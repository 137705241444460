import { Menu, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { IoIosArrowForward, IoMdFolder } from "react-icons/io";
import { Link, useParams } from "react-router-dom";
import DropdownMenu from "../../../../../components/layouts/dropdownMenu/DropdownMenu.component";


type Props = {
    directory: any,
    
}

export default function FoldersBreadcrumb({directory}: Props){
    const { t } = useTranslation()
    const {folderToken} = useParams();
    const [menuFolderOpened,setMenuFolderOpened] = useState(false);
    

    type BreadcrumbNameProps = {
        url: string
        name: string
        showArrow?: boolean
    }

    const BreadcrumbName = ({url,name,showArrow = false,...rest}:BreadcrumbNameProps) => (
        <div className={'flex items-center gap-2'} {...rest}>
            {showArrow && 
                <div>
                    <IoIosArrowForward  size={16} />
                </div>}
            <Link to={url}>
            <h3 style={{ fontSize:15 }} className={`${showArrow ? 'break-all':'break-word'}`}>{name}</h3>
          </Link>
        </div>
    )

    const FoldersMenu = ({folders}:any) => (
        <React.Fragment>
                <div>
                    <IoIosArrowForward  size={16} />
                </div>            
                <button className="rounded-full hover:bg-gray-300" onClick={() => setMenuFolderOpened(open => !open)}>
                <HiOutlineDotsHorizontal size={18}/>
            </button>

            <DropdownMenu open={menuFolderOpened} closeFunction={() => setMenuFolderOpened(false)}>
                {folders.map((folder:any,index:number) => (
                    <Link to={`/notes/${folder.token}`}  className="flex items-center gap-2 hover:bg-gray-300 py-1 px-2 text-gray-500" 
                    key={index}
                    onClick={() => setMenuFolderOpened(false)}>
                        <IoMdFolder size={16} />
                        <div className="truncate text-sm w-40 md:w-64 lg:w-72">
                            {folder.name}
                        </div>
                    </Link>
                ))}
            </DropdownMenu>
        </React.Fragment>
        
    )

    return (
        <div className="flex items-center gap-2" >
            <BreadcrumbName url='/' name={t('MY_NOTES')}/>

            <div className="hidden md:flex gap-2 items-center">
                
                {directory.preFolders && directory.preFolders.length > 1 && <FoldersMenu folders={directory.preFolders.slice(0,-1)}/>}
                
                {directory.preFolders && directory.preFolders.slice(-1).map((preFolder:any,index:number) => (
                <BreadcrumbName url={`/notes/${preFolder.token}`} name={preFolder.name} key={index} showArrow={true}/>
                ))}
                
            </div>
            <div className="flex md:hidden gap-3 items-center">
                {directory.preFolders && directory.preFolders.length > 0 && <FoldersMenu folders={directory.preFolders} />}
            </div>




        {folderToken &&             
          <BreadcrumbName url={`/notes/${directory.token}`} name={directory.name} showArrow={true}/>          
        }
      </div>

    )
}
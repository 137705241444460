import { createSlice, configureStore } from '@reduxjs/toolkit'

const REDUCER_KEY = 'COMPONENT_SIDEBAR'
export const sidebarReducer = createSlice({
    name: REDUCER_KEY,
    initialState: {
        value: true
    },
    reducers: {
        show: (state) => {
            state.value = true
        },
        hide: (state) => {
            state.value = false
        }
    }
})
import React from "react";
import { useEditorType } from "../../../../hooks/editor/useEditor";
import EditorNavbarComponent from "./editorNavbar/EditorNavbar.component";
import EditorSidebar from "./sidebar/EditorSidebar.component";

type props = {
    editor: useEditorType
}

export default function EditorHeader({editor}:props){

    return (
        <header>
            {editor.note && (
               <React.Fragment>
                {!editor.isNoteEditEstablishingConnection && <EditorNavbarComponent editor={editor}/>}
                    <EditorSidebar editor={editor}/>
               </React.Fragment>
            )}
        </header>
    )
}
import { IoMdFolder } from "react-icons/io";
import { Link } from "react-router-dom";
import styles from './NoteFolder.module.css'

type props = {
    folderName: string
    folderToken:string
}

export default function NoteFolderComponent({folderName,folderToken,...rest}:props){
  let item :any= null
  let oldBodyScroll :any= null
  let selectedItem:any = null

    function handleDrag(e:any){
      item.style.top = e.pageY+'px'
      item.style.left = e.pageX+'px'
    }
    
      function handleDrop(e:any){
        e.target.classList.remove(styles.dragItemOver)
      }

      function endDrag(e:any){
        document.body.style.overflowX = oldBodyScroll
        document.body.removeChild(item)
        item = null
        selectedItem = null
        oldBodyScroll = null
      }


      function handleDragStart(e:any){
        selectedItem = e.target
        item = e.target.cloneNode(true)
        item.style.position = 'absolute'
        item.style.top = e.target.pageY+'px'
        item.style.left = e.target.pageX+'px'
        item.style.width = e.target.offsetWidth+'px'
        item.style.height = e.target.offsetHeight+'px'
        e.dataTransfer.setDragImage(new Image(), 0, 0); 
        setTimeout(() => {
          document.body.appendChild(item);
          oldBodyScroll =  document.body.style.overflowX
          document.body.style.overflowX = 'hidden'
        },100)
      }

      function onDragOver(e:any){
        e.preventDefault()
        const overItem = e.target        
        if(overItem !== selectedItem){
          e.dataTransfer.effectAllowed = "move";
          overItem.classList.add(styles.dragItemOver)
        }
      }

      function onDragLeave(e:any){
        const overItem = e.target
        overItem.classList.remove(styles.dragItemOver)
      }

    return (
        <Link className="px-4 py-4 border rounded text-gray-500 flex items-center font-bold gap-2 text-sm folder bg-white" 
        to={`/notes/${folderToken}`}
        data-folder-token={`${folderToken}`}
        draggable
        onDrag={handleDrag}
        onDragStart={handleDragStart}
        onDragOver={onDragOver}
        onDragEnd={endDrag}
        onDragLeave={onDragLeave}
        onDrop={handleDrop}
        >
            <div className="pointer-events-none">
              <IoMdFolder size={20}/>
            </div>
            <div className="line-clamp-1 pointer-events-none">{folderName}</div>
        </Link>
    )
}
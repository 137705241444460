import { isString } from "lodash"
import { resolve } from "path"
import { useEffect, useState } from "react"
import { io, Socket, SocketOptions } from "socket.io-client"
import { ResponseType } from "../../hooks/api/useApi"

export const SOCKET_URL :string= process.env.REACT_APP_SOCKET_URL!


export class SocketUtils{
    public socket :null|Socket = null
    constructor(defaultSocket :Socket|null= null){
        this.socket = defaultSocket
    }

    initSocket(namespace: string){        
        if(this.socket){
            this.disconnect()
        }
        const locale = localStorage.getItem('locale') ?? 'en'
        const access_token = localStorage.getItem('access_token')
        let socketOptions = {
            transportOptions: {
                polling: {
                extraHeaders: {
                    'Authorization': access_token ? `Bearer ${access_token}`:null,
                    'Accept-Language': locale,
                    'App-Version': process.env.REACT_APP_VERSION,
                    'Device-Key': process.env.REACT_APP_DEVICE_KEY
                },
                },
            },
        }
        this.socket = io(`${SOCKET_URL}/${namespace}`,socketOptions)
        return this.socket
    }

    on(event: string,listener: Function){
        if(this.socket){
            return this.socket.on(event,(res) => listener(res))
        }
        return null
    }

    async disconnect(){
        if(this.socket ){
            this.socket.offAny()
            this.socket.disconnect()
        }
    }

    emit(event: string,params: any = {}){
        return new Promise<ResponseType>((resolve) => {
            let res :ResponseType = {
                success: false,
                errors: [],
                data: {},
                message: 'Error'
            }

            if(this.socket){
                return this.socket.emit(event,params,function (socket:any,ackfn:any) {
                    res = socket
                    resolve(res)
                })
            }else{
                console.log(`no socket for ${event}`)
                resolve(res)
            }
        })
        
    }
}
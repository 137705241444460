import React, { Suspense, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import baseTheme from "./application/themes/baseTheme";
import { useRoutes } from "react-router-dom";
import routes from "./routes/routes";
import CookiesComponent from "./components/parts/cookies/cookiesComponent/Cookies.component";
import ScrollToTop from "./components/parts/router/scrollToTop/ScrollToTop.component";
import useAuth from "./hooks/auth/useAuth";
import "./i18n";
import useApp from "./hooks/app/useApp";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ThemeUtils from "./utils/theme/ThemeUtils";
import useUserDirectory from "./hooks/userDirectory/useUserDirectory";
import moment from "moment";

const GOOGLE_CLIENT_ID: any = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID;

function App() {
  const auth = useAuth();
  const routing = useRoutes(routes(auth.user != null));
  const app = useApp()
  const [loading,setLoading] = useState(false)  

  useMemo(() => {
    initialize()
    ThemeUtils.applyTheme(baseTheme);
    const locale = window.navigator.language;
    if(locale){
      moment.locale(locale)
    }
  },[])

  async function initialize(){
    setLoading(true)
    await app.initApp()
    setLoading(false)
  }

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
    <Suspense fallback="loading">
      <div className="App">
        {!loading ? routing:<div>loading</div>}
        <ScrollToTop />
        <CookiesComponent />
      </div>
    </Suspense>
    </GoogleOAuthProvider>
  );
}

export default App;

export default class StringUtils {
    static stringReverse(string:string){
        var str = ''
        for(const letter of string){
            str = letter + str;
        }
        return str;
    }
    
    static removeHTML(string:string){ 
        var tmp = document.createElement("DIV");
        string = string.replaceAll('<br>',' ')
        tmp.innerHTML = string;
        return tmp.textContent || tmp.innerText || "";
    }

    static removeInitialCharacter(string:string,character:string){
        if(string.charAt(0) == character){
            string = string.slice(1);
        }
        return string
    }

    static removeHtmlFromString(string:string){
        var tmp = document.createElement("DIV");
        tmp.innerHTML = string;
        return tmp.textContent || tmp.innerText || ""
    }

    static editorBlockSubstring(contentBlocks:any,substring:string){
        if(!contentBlocks){
            return []
        }

        substring = substring.toLowerCase()
        const texts :{blockIndex:number,textAppearances: {text:string,highlight:boolean}[]}[]= []
        contentBlocks.forEach((block:any,index:number) => {
            const blockText = StringUtils.extractEditorBlockText(block)
            if(Array.isArray(blockText)){
                const filtered = blockText.filter((text:string) => text.toLowerCase().includes(substring))
                filtered.forEach((text:string) => {
                    texts.push({
                        blockIndex: index,
                        textAppearances: StringUtils.getHighlightedText(text,substring)
                    })
                })
                
            } else if(blockText && blockText.toLowerCase().includes(substring)){
                texts.push({
                    blockIndex: index,
                    textAppearances: StringUtils.getHighlightedText(blockText,substring)
                })
            }
        })
        return texts
    }

    static extractEditorBlockText(editorBlock:any){
        if(!editorBlock.type){
            return null
        }
        editorBlock.type = editorBlock.type.toLowerCase() 

        //TYPE TABLE
        if(editorBlock.type === 'table'){
            const content = editorBlock.data.content
            const texts :string[]= []
            if(content){
                content.forEach((contentArray:any) => {
                    contentArray.forEach((textContent:any) => {
                        if(textContent){
                            texts.push(textContent)
                        }
                    })
                })
            }
            return texts
        }

        //CODE
        if(editorBlock.type === 'code'){
            return editorBlock.data.code
        }

        //LIST
        if(editorBlock.type === 'list'){
            return editorBlock.data.items.map((item:any) => item.content)
        }

        //WARNING
        if(editorBlock.type === 'warning'){
            const texts :string[]= []
            if(editorBlock.data.title){
                texts.push(editorBlock.data.title)
            }

            if(editorBlock.data.message){
                texts.push(editorBlock.data.message)
            }
            return texts
        }

        //CHECKLIST
        if(editorBlock.type === 'checklist'){
            return editorBlock.data.items.map((item:any) => item.text)
        }

        //AnyButton
        if(editorBlock.type === 'anybutton'){
            const texts = []
            if(editorBlock.data.link){
                texts.push(editorBlock.data.link)
            }

            if(editorBlock.data.text){
                texts.push(editorBlock.data.text)
            }

            return texts
        }

        //alert
        if(editorBlock.type === 'alert'){
            return editorBlock.data.message ? StringUtils.removeHtmlFromString(editorBlock.data.message.replaceAll('<div>',' ').replaceAll('</div>','')):null
        }

        //DEFAULT RETURN, USED FOR HEADER AND PARAGRAPH
        return editorBlock.data.text
    }

    static getHighlightedText(text:string,highligh:string){
        const parts = text.split(new RegExp(`(${highligh})`,'gi'))

        const values :{text:string,highlight:boolean}[] = []
        parts.forEach(part => {
            if(part && part.length > 0){
                values.push({
                    text:part,
                    highlight: part.toLocaleLowerCase().includes(highligh.toLowerCase())
                })
            }
        })
        return values
    }
}
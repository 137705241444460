import { Menu, MenuItem } from "@mui/material";
import React, { useEffect } from "react";

type Props = {
    refOpenButtons: any[]
    children?:any
}

export default function PopoverMenuContainer({refOpenButtons,children}:Props){
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    useEffect(() => {
        refOpenButtons.forEach((refButton) => {
            if(refButton.current){
                refButton.current.addEventListener('click',handleClick)
            }
        })

        return () => {
            refOpenButtons.forEach((refButton) => {
                if(refButton.current){
                    refButton.current.addEventListener('click',handleClick)
                }
            })
        }
    },[])


    return (
        <Menu
        anchorEl={anchorEl}
        open={anchorEl != null}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "user-button",
        }}
        className="py-1 px-1"
      >
        {children}
      </Menu>
    )
}
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import DefaultLayout from "../../../components/layouts/defaultLayout/DefaultLayout.component";
import NoteFolderComponent from "./components/folder/NoteFolder.component";
import NoteComponent from "./components/note/Note.component";
import styles from "./Notes.module.css";
import {RiFolderInfoLine} from 'react-icons/ri'
import { useTranslation } from "react-i18next";
import NotesDirectoryContextMenu from "../../../components/parts/rightClickContextMenu/notesDirectoryContextMenu/NotesDirectoryContextMenu.component";
import useUserDirectory from "../../../hooks/userDirectory/useUserDirectory";
import { IoIosArrowForward } from "react-icons/io";
import FoldersBreadcrumb from "./components/foldersBreadcrumb/FoldersBreadcrumb.component";
import moment from "moment";
import LoaderContainer from "../../../components/containers/loaderContainer/LoaderContainer";

export default function NotesPage() {
  const userDirectory = useUserDirectory()
  const { t } = useTranslation()
  const [directory,setDirectory] = useState(userDirectory.defaultDirectory())
  const [loading,setLoading] = useState(true)
  const {folderToken} = useParams();

  useEffect(() => {
    reloadNotes()
  },[folderToken, userDirectory.directory,userDirectory.isConnected])

  useEffect(() => {
    reloadNotes()
  },[])

  function reloadNotes(){
    if(!folderToken && userDirectory.isConnected){
      setDirectory(userDirectory.directory)
      setLoading(false)
    }else if(userDirectory.isConnected && folderToken){
      const newDirectory = userDirectory.searchFolderByToken(folderToken)
      setDirectory(newDirectory)
      setLoading(false)
    }
  }

  return (
    <DefaultLayout className="px-2 md:px-5 lg:px-7 xl:px-10 pb-10">
      {directory && (
      <React.Fragment>
        <NotesDirectoryContextMenu />
      <div className="mt-3 mb-5 flex justify-between items-center text-gray-600">

          <FoldersBreadcrumb directory={directory}/>
      
        {/* <button title={t('FOLDER_DETAILS')}><RiFolderInfoLine className="text-gray-600" size={24}/></button> */}
      </div>

      
      {!folderToken && directory.lastModified && directory.lastModified.length > 0 && (
        <div className="mb-10">
          <h5 className="font-bold text-gray-600 text-sm">{t('LAST_MODIFIED')}</h5>
          <div
            className={`flex grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-2 mt-4 overflow-x-auto ${styles.lastModifiedContainer}`}
            style={{ flex: "0 0 25em" }}
            draggable={false}
          >
            {directory.lastModified.map((note:any, i:number) => (
              <NoteComponent
                style={{ maxWidth: 200, minWidth: 200, marginBottom: 10 }}
                titleSize={13}
                canDrag={false}
                key={i}
                name={note.name}
                lastModified={moment(note.updatedAt).fromNow()}
                noteToken={note.token}
              
              />
            ))}
          </div>
        </div>
      )}

      {directory && directory.folders && directory.folders.length > 0 && (
        <div className="mt-10">
        <h5 className="font-bold text-gray-600 text-sm">{t('FOLDERS')}</h5>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-5 mt-5">
          {directory && directory.folders.map((folder:any, i:number) => (
            <NoteFolderComponent key={i} folderName={folder.name} folderToken={folder.token}/>
          ))}
        </div>
      </div>
      )}

      {directory && directory.notes && directory.notes.length > 0 && (
        <div className="mt-10">
        <h5 className="font-bold text-gray-600 text-sm">{t('NOTES')}</h5>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-5 mt-5">
          {directory.notes.map((note:any, i:number) => (
            <NoteComponent setLoading={setLoading} key={i} name={note.name} content={note.contentHtml} noteToken={note.token} favorite={note.favorite}/>
          ))}
        </div>
      </div>
      )}
      </React.Fragment>
      )}
      <LoaderContainer visible={loading}/>
    </DefaultLayout>
  );
}
import styles from "./EditorSidebar.module.css";
import useSidebar from "../../../../../hooks/sidebar/useSidebar";
import React, { Ref, useEffect, useRef, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward, IoMdHome } from "react-icons/io";
import logo from "../../../../../assets/images/logos/postmetoo-nobackground.png";
import { Link, useParams } from "react-router-dom";
import { FaFolderOpen } from "react-icons/fa";
import { MdCreateNewFolder, MdFolder, MdFolderOpen } from "react-icons/md";
import {
  IoClose,
  IoDocumentLockOutline,
  IoDocumentTextOutline,
  IoSearch,
} from "react-icons/io5";
import DefaultDialog from "../../../../parts/dialogs/defaultDialog/DefaultDialog";
import { useTranslation } from "react-i18next";
import { HiOutlineDocumentAdd } from "react-icons/hi";
import FolderButtonComponent from "./components/FolderButton.component";
import { constants } from "buffer";
import NoteButton from "./components/NoteButton.component";
import NotesDirectoryContextMenu from "../../../../parts/rightClickContextMenu/notesDirectoryContextMenu/NotesDirectoryContextMenu.component";
import useEditor, {
  useEditorType,
} from "../../../../../hooks/editor/useEditor";
import useUserDirectory from "../../../../../hooks/userDirectory/useUserDirectory";
import SearchContainer from "../../../../containers/searchContainer/SearchContainer";
import { BiImageAdd } from "react-icons/bi";

type props = {
  editor: useEditorType;
};

export default function EditorSidebarComponent({ editor }: props) {
  const sidebar = useSidebar();
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const [showCreateFolder, setShowCreateFolder] = useState(false);
  const { t } = useTranslation();
  const { token } = useParams();
  let lastTokenChecked: string | null = null;
  const userDirectory = useUserDirectory()
  const [loading,setLoading] = useState(false)

  useEffect(() => {
    sidebar.closeMobileSidebar();

    return () => {
      sidebar.closeMobileSidebar();
    };
  }, []);

  useEffect(() => {
    openNote(token);
  }, [token]);

  async function openNote(token: string | undefined) {
    if (token && lastTokenChecked != token) {
      const res = await editor.userDirectory.openNote(token);
      if (res) {
        lastTokenChecked = token;
      }
    }
  }

  function closeSearchFunction() {
    setShowSearchModal(false);
  }

  function showSearchSidebar() {
    if (sidebar.isMobileSidebar) {
      sidebar.changeSidebar(false);
    }
    setShowSearchModal(true);
    setTimeout(() => {
      searchInputRef.current?.focus();
    }, 200);
  }


  async function openFolder(folder: any) {
    await editor.userDirectory.openCloseFolder(
      folder.token,
    );
  }

  function handlerClickNewFolder(){
    sidebar.closeMobileSidebar();
    setShowCreateFolder(true)
  }

  async function handlerClickNewNote(type = 0){
    setLoading(true)
    sidebar.closeMobileSidebar()
    await userDirectory.createNewNote(type)
    setLoading(false)
  }

  return (
    <React.Fragment>
      <aside
        className={`${sidebar.sidebar ? "h-screen" : "h-auto"} ${
          styles.sidebarContainer
        } ${
          sidebar.sidebar
            ? styles.sidebarContainerFull
            : styles.sidebarContainerIcons
        } break-all text-ellipsis overflow-hidden sidebarContainer`}
      >
        <div
          style={{ height: sidebar.navbarHeight }}
          className={`${
            sidebar.sidebar
              ? styles.firstContainersFull
              : styles.firstContainersIcon
          }`}
        >
          <Link
            to={"/"}
            className={`text-black font-bold items-center gap-3 ${styles.titleLogo}`}
            onClick={(e) => sidebar.closeMobileSidebar()}
          >
            <img src={logo} alt="logo postmetoo" width={24} />
            POSTMETOO
          </Link>
          <button onClick={() => sidebar.changeSidebar()}>
            {sidebar.sidebar ? (
              <IoIosArrowBack
                size={23}
                color="var(--theme-sidebar-text)"
                style={{ cursor: "pointer" }}
              />
            ) : (
              <IoIosArrowForward
                size={23}
                color="var(--theme-sidebar-text)"
                style={{ cursor: "pointer" }}
              />
            )}
          </button>
        </div>
            {sidebar.sidebar ? (
              <>
                        <div className="mt-2">
          <Link
            to={"/"}
            onClick={sidebar.closeMobileSidebar}
            title={t("RETURN_TO_HOME")}
            className={`flex items-center w-full cursor-pointer ${
              sidebar.sidebar
                ? styles.iconsContainersFull
                : styles.iconsContainersIcon
            }`}
          >
            <div className={`${sidebar.sidebar ? styles.buttonActionsIcons:''}`}>
              <IoMdHome size={19} />
            </div>
            <span className={styles.textOption}>{t("HOME")}</span>
          </Link>
          <button
            title={t("SEARCH_NOTE")}
            className={`flex items-center w-full cursor-pointer ${
              sidebar.sidebar
                ? styles.iconsContainersFull
                : styles.iconsContainersIcon
            }`}
            onClick={showSearchSidebar}
          >
            <div className={`${sidebar.sidebar ? styles.buttonActionsIcons:''}`}>
            <IoSearch size={19} />
            </div>
            <span className={styles.textOption}>{t("SEARCH")}</span>
          </button>
        </div>


        <div
          className={`${sidebar.sidebar ? "border-t pt-1 pb-10 mt-1" : ""}`}
          style={{ marginBottom: 70 }}
        >
          {editor.userDirectory.directory.folders.map(
            (folder: any, i: number) => (
              <FolderButtonComponent
                folder={folder}
                openFolder={openFolder}
                sidebar={sidebar}
                key={i}
              />
            )
          )}

          {editor.userDirectory.directory.notes.map((note: any, i: number) => (
            <NoteButton             
            onClick={sidebar.closeMobileSidebar}
            key={i} note={note} sidebar={sidebar} />
          ))}
        </div>
              </>
            ):null}

        <div
          className="fixed bottom-0 z-20 bg-sidebar"
          style={{ width: "inherit" }}
        >
          <button
            className={`block w-full cursor-pointer line-clamp-1 border-t ${
              sidebar.sidebar
                ? styles.bottomButtonContainerFull
                : styles.directoryContainerIcon
            }`}
            disabled={loading}
            onClick={handlerClickNewFolder}
          >
            <div className="flex gap-1">
              <MdCreateNewFolder size={18} />
            </div>
            <div
              className={`line-clamp-1 font-normal`}
              style={{ fontSize: 14 }}
            >
              New folder
            </div>
          </button>

          <button
            className={`block w-full cursor-pointer line-clamp-1 border-t ${
              sidebar.sidebar
                ? styles.bottomButtonContainerFull
                : styles.directoryContainerIcon
            }`}
            disabled={loading}
            onClick={(e) => handlerClickNewNote(0)}
          >
            <div className="flex gap-1">
              <HiOutlineDocumentAdd size={18} />
            </div>
            <div
              className={`line-clamp-1 font-normal`}
              style={{ fontSize: 14 }}
            >
              New note
            </div>
          </button>
          <button
            className={`block w-full cursor-pointer line-clamp-1 border-t ${
              sidebar.sidebar
                ? styles.bottomButtonContainerFull
                : styles.directoryContainerIcon
            }`}
            disabled={loading}
            onClick={(e) => handlerClickNewNote(1)}
          >
            <div className="flex gap-1">
              <BiImageAdd size={18} />
            </div>
            <div
              className={`line-clamp-1 font-normal`}
              style={{ fontSize: 14 }}
            >
              New draw
            </div>
          </button>
        </div>
      </aside>
      {sidebar.sidebar && (
        <div
          className={styles.backdropMobile}
          onClick={() => sidebar.changeSidebar()}
        />
      )}

      <SearchContainer open={showSearchModal} closeSearchFunction={closeSearchFunction}/>

      <NotesDirectoryContextMenu
        containerParentClass="sidebarContainer"
        defaultShowCreateFolder={showCreateFolder}
        setDefaultShowCreateFolder={(value: boolean) =>
          setShowCreateFolder(value)
        }
      />
    </React.Fragment>
  );
}

import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";

export default function CookiesComponent(){
    const { t, i18n } = useTranslation();

    return(
        <CookieConsent
            location="bottom"
            buttonText={t("COOKIES.BUTTON")}
            cookieName="cookies"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px", borderRadius: '3px' }}
            expires={150}
        >
          {t("COOKIES.TEXT")} <a href="/cookies" className="d-block d-md-inline-block mx-md-2" style={{ color: "#FFD32D"  }}>{t("SEE_MORE")}</a>
        </CookieConsent>
    );

}
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useAuth from "../auth/useAuth";
import { useBeforeunload } from 'react-beforeunload';
import JsonUtils from "../../utils/json/JsonUtils";
import { RootState } from "../../reducers/reducers";
import { directoryActions, directorySocketReducer, searchDirectoryFolderByToken, searchDirectoryNoteByToken } from "../../reducers/notes/socket/directorySocketReducer";
import { ResponseType } from "../api/useApi";


export default function useUserDirectory(){
    const DIRECTORY_REDUCER = directorySocketReducer
    const DIRECTORY_ACTIONS = directoryActions
    
    const auth = useAuth()
    const directoryState = useSelector((state:RootState) => state[DIRECTORY_REDUCER.name])
    let connectionStarted = directoryState.isConnected || directoryState.isEstablishingConnection ? true:false

    const dispatch = useDispatch()
    const {folderToken} = useParams()
    const {t} = useTranslation()
    const navigate = useNavigate()

    const DEFAULT_NEW_FOLDER_BASE_NAME = t('UNTITLED_FOLDER')
    const DEFAULT_NEW_NOTE_BASE_NAME = t('UNTITLED_NOTE')
    const DEFAULT_NEW_DRAW_BASE_NAME = t('UNTITLED_DRAW')

    useBeforeunload(() =>  
        disconnect()
    );

    useEffect(() => {
        if(!auth.user && (directoryState.isConnected || directoryState.isEstablishingConnection)){
            disconnect()
        }else if(auth.user && !connectionStarted && !directoryState.isConnected && !directoryState.isEstablishingConnection){
            startConnection()
        }
    },[auth.user])

    async function startConnection(){
        connectionStarted = true
        await dispatch(DIRECTORY_ACTIONS.startConnecting())
    }

    async function disconnect(){
        await dispatch(DIRECTORY_ACTIONS.directorySocketDisconnect())
    }

    function defaultDirectory(){
        return DIRECTORY_REDUCER.getInitialState().directory
    }

    function searchFolderByToken(token:string,searchDirectory:any = null){
        if(!searchDirectory){
            searchDirectory = getClonnedDirectory()
        }

        return searchDirectoryFolderByToken(token,searchDirectory)
    }

    function searchNoteByToken(token:string|null|undefined, searchDirectory:any = null){
        if(!token || typeof token == 'undefined'){
            return null
        }

        if(!searchDirectory){
            searchDirectory = getClonnedDirectory()
        }

        return searchDirectoryNoteByToken(token,searchDirectory)
    }

    function searchFolderByName(name:string,tokenFolder:string|null = null,searchDirectory:any = null,defaultReturn = true, isParentCall = false){
        if(!searchDirectory){
            searchDirectory = getClonnedDirectory()
        }

        let foundFolder :any = null
        searchDirectory.folders.find((folder:any) => {
            if(folder.name == name && ((!tokenFolder && !folder.parentId) || isParentCall)){
                foundFolder = {...folder,preFolders: []}
            }else if(folder.folders && folder.folders.length > 0){
                const isParentCall = tokenFolder === folder.token ? true:false
                const childFolder :any= searchFolderByName(name,tokenFolder,folder,false,isParentCall)
                if(childFolder){
                    childFolder.preFolders.unshift({
                        name: folder.name,
                        token: folder.token
                    })
                   
                    foundFolder = {...childFolder}
                }
            }
            return foundFolder != null
        })

        return foundFolder
    }

    function searchNoteByName(name:string,tokenFolder:string|null = null,searchDirectory:any = null,defaultReturn = true, isParentCall = false){
        if(!searchDirectory){
            searchDirectory = getClonnedDirectory()
        }

        if(tokenFolder){
            searchDirectory = searchFolderByToken(tokenFolder)
        }

        let foundNote :any= null
        searchDirectory.notes.find((note:any) => {
            if(note.name === name){
                foundNote = note
            }
            return foundNote != null
        })

        return foundNote
    }

    async function openCloseFolder(token:string,searchDirectory:any = null, first = true){
        if(!searchDirectory){
            searchDirectory = getClonnedDirectory()
        }

        let changed = false
        await Promise.all(searchDirectory.folders.map(async (folder:any) => {
            if(folder.token == token){
                changed = true
                folder.lastOpened = true
                folder.opened = folder.opened ? false: true
                if(folder.folders){
                    folder.folders.map((subFolder:any) => {
                        subFolder.lastOpened = false
                    })
                }
            }else{
                folder.lastOpened = false
                if(folder.folders && folder.folders.length > 0){
                    const changedChild = await openCloseFolder(token,folder,false)
                    if(changedChild ){
                        changed = true
                        folder.opened = true
                    }else{
                        folder.lastOpened = false
                    }
                }
            }
        }))

        if(changed && first){
            await dispatch(DIRECTORY_ACTIONS.setDirectory({directory: searchDirectory, ignoreOldDirectory: true}))
        }
        return changed
    }

    async function openNote(token:string,searchDirectory:any, first = true){
        if(!searchDirectory){
            searchDirectory = getClonnedDirectory()
        }

        let changed = false
        if(searchDirectory.notes){
            searchDirectory.notes.forEach((note:any) => {
                if(note.token == token){
                    note.opened = true
                    changed = true
                }else{
                    note.opened = false
                }
            })
        }

        if(searchDirectory.folders){
            await Promise.all(searchDirectory.folders.map(async (folder:any) => {
                folder.lastOpened = false
                const changedChild = await openNote(token,folder,false)
                if(changedChild){
                    folder.opened = true
                    changed = true
                }
            }))
        }

        if(changed && first){
            await dispatch(DIRECTORY_ACTIONS.setDirectory({directory: searchDirectory,ignoreOldDirectory: true}))
        }
        return changed
    }



    function calculateFolderUniqueNameFromBase(baseName:string|null = null,folderTokenCalculate:string|undefined = folderToken){
        if(!baseName){
            baseName = DEFAULT_NEW_FOLDER_BASE_NAME
        }
        let exists = true
        let name = ''
        let counter = -1;
        while(exists){
          counter++;
          name = counter > 0 ? `${baseName} ${counter}`:baseName
          exists = searchFolderByName(name,folderTokenCalculate)
        }
        return name
    }

    function calculateNoteUniqueNameFromBase(baseName:string,folderTokenCalculate:string|undefined = folderToken){
        let exists = true
        let name = ''
        let counter = -1;
        while(exists){
          counter++;
          name = counter > 0 ? `${baseName} ${counter}`:baseName
          exists = searchNoteByName(name,folderTokenCalculate)
        }
        return name
    }

    async function createNewFolder(name: string,autoParenToken = true,parentToken:string|undefined = undefined){
        if(autoParenToken){
            parentToken = folderToken
        }
        return await dispatch(DIRECTORY_ACTIONS.createNewFolder({name,parentToken: parentToken ?? null}))
    }

    async function deleteFolder(folderToken:string){
        return await dispatch(DIRECTORY_ACTIONS.deleteFolder({folderToken}))
    }

    async function deleteNote(noteToken:string){
        return await dispatch(DIRECTORY_ACTIONS.deleteNote({noteToken}))
    }

    async function setEditingNote(editingNote:any){
        return await dispatch(DIRECTORY_ACTIONS.setEditingNote({editingNote}))
    }

    async function createNewNote(type = 0,autoParenToken = true,parentToken:string|undefined = undefined,name:string|null = null){
        if(autoParenToken){
            parentToken = folderToken
        }
        if(!name){
            name = calculateNoteUniqueNameFromBase(type == 0 ? DEFAULT_NEW_NOTE_BASE_NAME:DEFAULT_NEW_DRAW_BASE_NAME,parentToken)
        }
        const res :any= await dispatch(DIRECTORY_ACTIONS.createNewNote({name,parentToken: parentToken ?? null,type}))
        if(res.success){
            navigate(`/note/${res.data.noteToken}`)
        }
    }

    async function setNoteFavorite(noteToken:string,favoriteStatus:boolean){
        return await dispatch(DIRECTORY_ACTIONS.setNoteFavoriteStatus({noteToken,favoriteStatus}))
    }

    async function setEditingNoteName(name:string|null){
        return await dispatch(DIRECTORY_ACTIONS.setEditingNoteName({name}))
    }

    function getClonnedDirectory(){
        return JsonUtils.cloneJson(directoryState.directory)
    }


    return {
        directory: directoryState.directory,
        isConnected: directoryState.isConnected,
        editingNote: directoryState.editingNote,
        editingNoteName: directoryState.name,
        editingNoteContentBlocks: directoryState.contentBlocks,
        setEditingNoteName,
        disconnect,
        defaultDirectory,
        searchFolderByToken,
        openCloseFolder,
        openNote,
        searchNoteByToken,
        searchFolderByName,
        searchNoteByName,
        calculateFolderUniqueNameFromBase,
        calculateNoteUniqueNameFromBase,
        createNewFolder,
        deleteFolder,
        deleteNote,
        createNewNote,
        setNoteFavorite,
        directoryState,
        DIRECTORY_ACTIONS,
        setEditingNote
    }
}
/* eslint-disable react/no-unescaped-entities */
import { Button, Link } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FloatingLabelPasswordInputComponent from "../../../components/form/inputs/floatingLabelPasswordInput/FloatingLabelPasswordInput.component";
import useApi from "../../../hooks/api/useApi";
import useAuth from "../../../hooks/auth/useAuth";
import ToastUtils from "../../../utils/toast/ToastUtils";


export default function SetPasswordPage() {
  const auth = useAuth();
  const api = useApi();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const passwordRef: any = useRef();
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    passwordRef.current?.focus();
    auth.setPasswordPageViewed(true)
    if (auth.user.hasPassword) {
      navigate('/',{replace: true})    
    }
  }, []);



  async function setPasswordForm(event: any) {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
      const errors = [];

      if (password.length < 5) {
        errors.push("password");
      }

      if (errors.length > 0) {
        setFormErrors(errors);
        setLoading(false);
        return;
      }

      const res = await api.post('auth/set-password',{password},{crypt: true});

      if (res.success) {
        const user = {...auth.user};
        user.hasPassword = true;
        auth.setPasswordPageViewed(true)
        auth.setUser(user);
        navigate('/',{replace: true});
      } else {
        setLoading(false);
        if (res.errors.includes("password")) {
          setFormErrors(res.errors);
          ToastUtils.showToast("error", { message: "Check the errors and try again!" });
        }else{
            setFormErrors([]);
        }
      }
    }
  }
  return (
    <React.Fragment>
     
        <div className="flex h-screen w-screen bg-body-public px-2 content-center items-center">
          <div className="rounded-lg container shadow-xl border bg-white m-auto py-8 md:pt-14 px-3 md:px-10 max-w-lg">
            <div>
              <h3 className="text-xl">{t('SET_YOUR_PASSWORD')}</h3>
              <p className="text-sm text-gray-600 mt-3">
                {t('SET_PASSWORD_NOTICE')}
              </p>
            </div>
            <form onSubmit={setPasswordForm}>
              <div className="mt-10">
                <div>
                  <div className="mt-4">
                    <FloatingLabelPasswordInputComponent
                      value={password}
                      label={t('PASSWORD')}
                      onChange={(e: any) => setPassword(e.target.value)}
                      showPassword={showPassword}
                      showPasswordFunction={() =>
                        setShowPassword(!showPassword)
                      }
                      inputRef={passwordRef}
                      error={formErrors.includes("password")}
                      helperText="The password must has 5 characters minimum"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-10">
              <button
                  className="bg-secondary rounded w-full p-3 shadow-lg font-bold text-sm"
                  type="submit"
                  disabled={loading}
                >
                  {t("SET_PASSWORD")}
                </button>
              </div>
            </form>

            <div className="mt-8 text-center">
              <Link
                className="text-sm no-underline text-gray-600 hover:underline cursor-pointer"
                onClick={() => navigate('/',{replace: true})}
              >
                {t('CONTINUE_WITHOUT_PASSWORD')}
              </Link>
            </div>
          </div>
        </div>
      
    </React.Fragment>
  );
}

import { Button } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import GoogleButtonComponent from "../../../components/form/buttons/googleButton/GoogleButton.component";
import FloatingLabelInput from "../../../components/form/inputs/floatingLabelInput/FloatingLabelInput.component";
import FloatingLabelPasswordInput from "../../../components/form/inputs/floatingLabelPasswordInput/FloatingLabelPasswordInput.component";
import DefaultLayout from "../../../components/layouts/defaultLayout/DefaultLayout.component";
import useApi from "../../../hooks/api/useApi";
import useAuth from "../../../hooks/auth/useAuth";
import useUserDirectory from "../../../hooks/userDirectory/useUserDirectory";
import { useReducers } from "../../../reducers/reducers";
import StringUtils from "../../../utils/string/StringUtils.utils";
import ToastUtils from "../../../utils/toast/ToastUtils";
import showToast from "../../../utils/toast/ToastUtils";
import StringValidator from "../../../utils/validator/StringValidator";

export default function LoginPage() {
  const navigate = useNavigate();
  const auth = useAuth();
  const { t } = useTranslation();
  const api = useApi();
  const [emailChecked, setEmailChecked] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const reducers = useReducers()
  const emailRef: any = useRef();
  const passwordRef: any = useRef();
  const directory = useUserDirectory()

  useEffect(() => {
    setShowPassword(false);
    setPassword("");
    emailRef.current?.focus();
    reducers.clearAll()
    directory.disconnect()
  }, []);

  const handleClickForgot = (e: any) => {
    e.preventDefault();
    navigate('/forgot-password')
  };

  const handleClickSignin = async (e: any) => {
    e.preventDefault();
      if (!api.loading) {
        await setLoading(true);
        const errors = [];

        if (!StringValidator.validateEmail(email)) {
          errors.push("email");
        }

        if (StringValidator.emptyString(password)) {
          errors.push("password");
        }

        setFormErrors(errors);
        if (errors.length > 0) {
          ToastUtils.showToast("error", { message: "Check the errors and try again!" });
          await setLoading(false);
          return;
        }

        const res = await api.post('auth/signin',{ email, password },{crypt: true,autoLogout: false})
        
      
        if (res.success) {
          const { user, token } = res.data;
          await auth.login(user,token);
          navigate(user.hasPassword ? '/':'/set-password',{replace: true});
        } else {
          ToastUtils.showToast("error", { message: "Check the errors and try again!" });
          setFormErrors(res.errors);
          await setLoading(false);
        }
      }
  };

  const handleClickNext = async (e: any) => {
    e.preventDefault();
    passwordRef.current?.focus();
    if (!api.loading) {
      await setLoading(true);
      if (StringValidator.validateEmail(email)) {
        const res = await api.post(
          "auth/check-email",
          { email },
          { crypt: true }
        );
        await setFormErrors(
          res.success && res.data.userExists ? [] : ["email"]
        );
        await setEmailChecked(res.success && res.data.userExists);
        await setLoading(false);
        setTimeout(() => {
          passwordRef.current?.focus();
        },200)
      } else {
        await setFormErrors(["email"]);
        ToastUtils.showToast("error", { message: "Check the errors and try again!" });
        await setLoading(false);
      }
    }
  };

  return (
    <DefaultLayout>
      <div className="flex h-full w-full bg-body-public px-5 content-center items-center mt-14 md:mt-40">
        <div className="rounded-lg container shadow-lg bg-white m-auto py-8 md:pt-14 px-5 md:px-10 max-w-lg border">
          <div>
            <h3 className="text-2xl font-bold">{t("LOGIN")}</h3>
            <p className="text-base text-gray-700">
              {t("TO_ACCESS_TO_YOUR_NOTES")}
            </p>
          </div>
          <form onSubmit={!emailChecked ? handleClickNext : handleClickSignin}>
            <div className="mt-10">
              {!emailChecked ? (
                <FloatingLabelInput
                  helperText="Incorrect or inexistent email"
                  error={formErrors.includes("email")}
                  label={t("EMAIL")}
                  onChange={(e: any) => setEmail(e.target.value)}
                  inputRef={emailRef}
                />
              ) : (
                <div>
                  <div>
                    <FloatingLabelInput
                      value={email}
                      helperText="Incorrect or inexistent email"
                      error={formErrors.includes("email")}
                      label={t("EMAIL")}
                      onChange={(e: any) => setEmail(e.target.value)}
                    />
                  </div>

                  <div className="mt-4">
                    <FloatingLabelPasswordInput
                      value={password}
                      label={t("PASSWORD")}
                      onChange={(e: any) => setPassword(e.target.value)}
                      showPassword={showPassword}
                      showPasswordFunction={() =>
                        setShowPassword(!showPassword)
                      }
                      inputRef={passwordRef}
                      error={formErrors.includes("password")}
                      helperText="Incorrect or inexistent email"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="mt-10">
              {!emailChecked ? (
                <button
                  className="bg-secondary rounded w-full p-3 shadow-lg font-bold text-sm"
                  type="submit"
                  disabled={loading}
                >
                  {t("NEXT")}
                </button>
              ) : (
                <button
                  className="bg-secondary rounded w-full p-3 text-md shadow-lg font-bold text-sm"
                  type="submit"
                  disabled={loading}
                >
                  {t("LOGIN")}
                </button>
              )}
            </div>
          </form>
          <div className="w-full flex justify-center">
            <GoogleButtonComponent />
          </div>
          <div className="mt-6 text-center">
            <Link
              to="/"
              onClick={handleClickForgot}
              className="text-md no-underline hover:underline cursor-pointer"
            >
              {t("FORGOT_PASSWORD")}?
            </Link>
          </div>
          <div className="mt-8 text-center">
            <Link
              className="text-sm no-underline text-gray-600 hover:underline cursor-pointer"
              to={"/register"}
            >
              {t("CREATE_A_FREE_ACCOUNT")}
            </Link>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}

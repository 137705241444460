import { useEditorType } from "../../../hooks/editor/useEditor";
import useSidebar from "../../../hooks/sidebar/useSidebar";
import EditorHeader from "../../navigation/header/editorHeader/EditorHeader.component";
import styles from "./DefaultEditLayout.module.css";

type props = {
  children: React.ReactNode;
  className?: any;
  editor: useEditorType
  style?:any
};

export default function DefaultEditLayout({ editor,className,style = {}, children }: props) {
  const sidebar = useSidebar();

  return (
    <div>
      <EditorHeader editor={editor}/>
      {!editor.isNoteEditEstablishingConnection && 
          <main
          style={style}
          className={`${styles.mainContainer} ${
            sidebar.sidebar ? styles.fullSidebarBody : styles.iconSidebarBody
          } ${className}`}
        >
          {children}
        </main>
      }
    </div>
  );
}

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers/reducers";
import {MdWork,MdSpaceDashboard} from 'react-icons/md'
import { useEffect, useState } from "react";
import { sidebarReducer } from "../../reducers/components/sidebar/sidebarReducer";
import useWindowSize from "../window/useWindowSize";
import { sidebarMobileReducer } from "../../reducers/components/sidebar/sidebarMobileReducer";

const FULL_SIDEBAR_REDUCER = sidebarReducer
const MOBILE_SIDEBAR_REDUCER = sidebarMobileReducer
export default function useSidebar(){
    const fullWidth = 265
    const iconsWidth =  75
    const navbarHeight = 50

    const fullSidebar = useSelector((state:RootState) => state[FULL_SIDEBAR_REDUCER.name].value)
    const mobileSidebar = useSelector((state:RootState) => state[MOBILE_SIDEBAR_REDUCER.name].value)
    const dispatch = useDispatch()
    const windowSize = useWindowSize()


    function sidebarOptionClicked(openIndex:number){
        // const newOptions = [...options]
        // newOptions.forEach((option,index) => {
        //     if(option.children){
        //         option.opened = openIndex == index ? !option.opened:false
        //     }
        // })
        // setOptions(newOptions)
    }

    function getCurrentMdWidth(inPixels = true){
        const currentWidth = fullSidebar ? fullWidth:iconsWidth
        return inPixels ? `${currentWidth}px`:currentWidth
    }

    function changeSidebar(show:any = null){
        if(windowSize.isMobileSize){
            if(show === null){
                show = !mobileSidebar
            }
            console.log("lets show mobile",show)
            dispatch(show ? MOBILE_SIDEBAR_REDUCER.actions.show():MOBILE_SIDEBAR_REDUCER.actions.hide())
        }else{
            if(show === null){
                show = !fullSidebar
            }
            dispatch(show ? FULL_SIDEBAR_REDUCER.actions.show():FULL_SIDEBAR_REDUCER.actions.hide())
        }
    }

    function closeMobileSidebar() {
        if (windowSize.isMobileSize) {
          changeSidebar(false);
        }
      }
    
    return {
        sidebar: windowSize.isMobileSize ? mobileSidebar:fullSidebar,
        isMobileSidebar: windowSize.isMobileSize ? true:false,
        sidebarOptionClicked,
        changeSidebar,
        fullWidth,
        iconsWidth,
        getCurrentMdWidth,
        navbarHeight,
        closeMobileSidebar
    }
}

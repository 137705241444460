import React, { useEffect, useRef, useState } from "react";
import styles from "./NotesDirectoryContextMenu.module.css";
import { RiStarFill, RiStarLine } from "react-icons/ri";
import { FiFolderPlus, FiTrash } from "react-icons/fi";
import {
  MdLockOutline,
  MdOutlineModeEditOutline,
  MdPostAdd,
} from "react-icons/md";
import { IoPeople } from "react-icons/io5";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { FaFolderOpen } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import CreateFolderDialog from "../../dialogs/createFolderDialog/CreateFolderDialog.component";
import useUserDirectory from "../../../../hooks/userDirectory/useUserDirectory";
import useAuth from "../../../../hooks/auth/useAuth";
import { useDispatch } from "react-redux";
import ConfirmDialog from "../../dialogs/confirmDialog/ConfirmDialog.component";
import LoaderContainer from "../../../containers/loaderContainer/LoaderContainer";
import { BiImageAdd } from "react-icons/bi";

type Props = {
  containerParentClass?: string|null
  defaultShowCreateFolder?:boolean
  setDefaultShowCreateFolder?:Function|null
}


export default function NotesDirectoryContextMenu({defaultShowCreateFolder = false,setDefaultShowCreateFolder = null,containerParentClass = null}:Props) {
  const userDirectory = useUserDirectory()
  const [visible, setVisible] = useState(false);
  const menuRef: any = useRef(null);
  const [folderClick, setFolderClick]: any = useState(null);
  const [noteClick, setNoteClick] :any= useState(null);
  const [lastClickEvent,setLastClickEvent]:any= useState(null)
  const [showCreateFolder,setShowCreateFolder] = useState(false)
  const [showDeleteFolder,setShowDeleteFolder] = useState(false)
  const [showDeleteNote,setShowDeleteNote] = useState(false)
  const [loading,setLoading] = useState(false)
  const {token} = useParams()
  const { t } = useTranslation();
  const navigate = useNavigate()


  useEffect(() => {
    document.addEventListener("contextmenu", handleContextMenu);
    document.addEventListener("click", handleClick);
    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("click", handleClick);
      document.removeEventListener("scroll", handleScroll);
    };
  }, [userDirectory.directory]);

  useEffect(() => {
    if(lastClickEvent && visible){
      const clickX = lastClickEvent.clientX;
      const clickY = lastClickEvent.clientY;
      const screenW = window.innerWidth;
      const screenH = window.innerHeight;
      const rootW = menuRef.current.offsetWidth;
      const rootH = menuRef.current.offsetHeight;
      const right = screenW - clickX > rootW;
      const left = !right;
      const top = screenH - clickY > rootH;
      const bottom = !top;

      if (right) {
        menuRef.current.style.left = `${clickX + 5}px`;
      }

      if (left) {
        menuRef.current.style.left = `${clickX - rootW - 5}px`;
      }

      if (top) {
        menuRef.current.style.top = `${clickY + 5}px`;
      }

      if (bottom) {
        menuRef.current.style.top = `${clickY - rootH - 5}px`;
      }
    }
  },[lastClickEvent,noteClick,folderClick,visible])

  useEffect(() => {
    setShowCreateFolder(defaultShowCreateFolder)
  },[defaultShowCreateFolder])


  async function handleContextMenu(event: any){
    event.preventDefault();
    const target = event.target;
    setVisible(false);
    setLastClickEvent(null)
    //if has parent class and the click is not inside the parent
    if(containerParentClass && !target.closest(`.${containerParentClass}`)){
      setNoteClick(null);
      setFolderClick(null);
      return;      
    }

    //IS FOLDER
    if (target.closest(".folder")) {
      const token = target.closest(".folder").getAttribute("data-folder-token")
      const folder = await userDirectory.searchFolderByToken(token)
      setFolderClick(folder);
      setNoteClick(null);
    } else if (target.closest(".note")) {
      //IS NOTE
      const token = target.closest(".note").getAttribute("data-note")
      const note = await userDirectory.searchNoteByToken(token)
      setNoteClick(note);
      setFolderClick(null);
    } else {
      //IS NOTHING
      setNoteClick(null);
      setFolderClick(null);
    }
    setLastClickEvent(event)
    setVisible(true);
  };
  

  const handleClick = (event: any) => {
    setLastClickEvent(null)
    setVisible(false);
  };

  const handleScroll = (event: any) => {
    setLastClickEvent(null)
    if (visible) setVisible(false);
  };

  const handleOpenFolder = (event:any,newTab = false) => {
    const path = `/notes/${folderClick.token}`
    if(newTab){
      const win :any= window.open(path, "_blank");
      win.focus();
    }else{
      navigate(path)
    }
  }

  const handleOpenNote = (event:any,newTab = false) => {
    const path = `/note/${noteClick.token}`
    if(newTab){
      const win :any= window.open(path, "_blank");
      win.focus();
    }else{
      navigate(path)
    }
  }

  const handleNewFolderAction = (value:boolean) => {
    if(setDefaultShowCreateFolder){
      setDefaultShowCreateFolder(value)
    }
    setShowCreateFolder(value)
  }

  const handleNewNote = async (type = 0) => {
    setLoading(true)
    const created = await userDirectory.createNewNote(type)
    setLoading(true)
  }

  const handleClickDeleteFolder = () => {
    setShowDeleteFolder(true)
    setVisible(false)
  }

  const handleClickDeleteNote = () => {
    setShowDeleteNote(true)
    setVisible(false)
  }

  const handleDeleteFolder = async () => {
    setShowDeleteFolder(false)
    setLoading(true)
    await userDirectory.deleteFolder(folderClick.token)
    setLoading(false)
  }

  const handleDeleteNote = async () => {
    setShowDeleteNote(false)
    setLoading(true)
    await userDirectory.deleteNote(noteClick.token)
    if(token && noteClick.token === token){
      navigate('/')
    }
    setLoading(false)
  }

  const handleFavorite = async () => {
    setLoading(true)
    await userDirectory.setNoteFavorite(noteClick.token,!noteClick.favorite)
    setLoading(false)
  }

  return (
      <React.Fragment>
        <div
      ref={menuRef}
      className={`${
        styles.contextMenu
      } rounded py-3 shadow-2xl border font-bold text-sm ${
        visible && !loading ? "" : "hidden"
      } text-gray-700`}
    >
      {!folderClick && !noteClick && (
        <React.Fragment>
          <button className="flex px-5 py-2 cursor-pointer hover:bg-gray-200 w-full items-center gap-5" onClick={() => handleNewFolderAction(true)}>
            <FiFolderPlus className="text-gray-600" size={19} />
            {t('NEW_FOLDER')}
          </button>
          <button className="flex px-5 py-2 cursor-pointer hover:bg-gray-200 w-full items-center gap-5" onClick={() => handleNewNote(0)}>
            <MdPostAdd className="text-gray-600" size={21} />
            {t('NEW_NOTE')}
          </button>
          <button className="flex px-5 py-2 cursor-pointer hover:bg-gray-200 w-full items-center gap-5" onClick={() => handleNewNote(1)}>
            <BiImageAdd className="text-gray-600" size={21} />
            {t('NEW_DRAW')}
          </button>
        </React.Fragment>
      )}
      {folderClick && folderClick.permission && (
        <React.Fragment>
          <button className="flex px-5 py-2 cursor-pointer hover:bg-gray-200 w-full items-center gap-5" onClick={handleOpenFolder}>
            <FaFolderOpen size={19} className="text-gray-600" />
            {t('OPEN')}
          </button>
          <button className="flex px-5 py-2 cursor-pointer hover:bg-gray-200 w-full items-center gap-5" onClick={(e:any) => handleOpenFolder(e,true)}>
            <BsBoxArrowUpRight size={15} className="text-gray-600" />
            {t('OPEN_IN_NEW_TAB')}
          </button>
          <hr className={`my-2`} />
          {/* <button className="flex px-5 py-2 cursor-pointer hover:bg-gray-200 w-full items-center gap-5">
            <MdLockOutline size={18} className="text-gray-600" />
            {t('LOCK')}
          </button>
          <button className="flex px-5 py-2 cursor-pointer hover:bg-gray-200 w-full items-center gap-5">
            <IoPeople size={18} className="text-gray-600" />
            {t('SHARE')}
          </button> */}
          {folderClick.permission.canDelete && 
            <button className="flex px-5 py-2 cursor-pointer hover:bg-gray-200 w-full items-center gap-5 text-red-500" onClick={handleClickDeleteFolder}>
            <FiTrash className="text-red-500" size={17} />
            {t('DELETE_FOLDER')}
          </button>
          }
        </React.Fragment>
      )}
      {noteClick && noteClick.permission && (
        <React.Fragment>
          <button className="flex px-5 py-2 cursor-pointer hover:bg-gray-200 w-full items-center gap-5" onClick={handleOpenNote}>
            <MdOutlineModeEditOutline size={19} className="text-gray-600" />
            {t('EDIT')}
          </button>
          <button className="flex px-5 py-2 cursor-pointer hover:bg-gray-200 w-full items-center gap-5" onClick={(e:any) => handleOpenNote(e,true)}>
            <BsBoxArrowUpRight size={15} className="text-gray-600" />
            {t('EDIT_IN_NEW_TAB')}
          </button>

          <hr className={`my-2`} />
          {/* <RiStarFill size={21} className="text-gray-700" /> */}
          <button className="flex px-5 py-2 cursor-pointer hover:bg-gray-200 w-full items-center gap-5" onClick={handleFavorite}>
            {noteClick.favorite ? 
              <RiStarFill size={18} className="text-secondary"/>:
              <RiStarLine size={18} className="text-gray-600" />
              }
            {noteClick.favorite ? 
              t('UN_MARK_AS_FAVORITE'):
              t('MARK_AS_FAVORITE')
          
            }
          </button>
          <button className="flex px-5 py-2 cursor-pointer hover:bg-gray-200 w-full items-center gap-5" >
            <MdLockOutline size={18} className="text-gray-600" />
            {t('LOCK')}
          </button>
          {/* <button className="flex px-5 py-2 cursor-pointer hover:bg-gray-200 w-full items-center gap-5">
            <IoPeople size={18} className="text-gray-600" />
            {t('SHARE')}
          </button> */}
          {noteClick.permission && noteClick.permission.canDelete && (
            <button className="flex px-5 py-2 cursor-pointer hover:bg-gray-200 w-full items-center gap-5 text-red-500" onClick={handleClickDeleteNote}>
              <FiTrash className="text-red-500" size={17} />
              {t('DELETE_NOTE')}
            </button>
          )
          }
          
        </React.Fragment>
      )}
        
      <CreateFolderDialog showCreateFolder={showCreateFolder} closeFunction={() => handleNewFolderAction(false)}/>
        
    </div>
          <ConfirmDialog open={showDeleteFolder} 
          onCancel={() => setShowDeleteFolder(false)} 
          onAction={handleDeleteFolder}
          type='delete'
          loading={loading}
        />

      <ConfirmDialog open={showDeleteNote} 
            onCancel={() => setShowDeleteNote(false)} 
            onAction={handleDeleteNote}
            type='delete'
            loading={loading}
          />

      <LoaderContainer visible={loading}/>
      </React.Fragment>
  );
}

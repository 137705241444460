import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { authReducers } from "./auth/AuthReducers";
import { componentsReducers } from "./components/ComponentsReducers";
import { NotesReducers,NotesMiddlewares } from "./notes/NotesReducers";
import { persistCombineReducers, persistStore } from "redux-persist";
import { useDispatch } from "react-redux";
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web


const persistConfig = {
    key: 'root',
    storage,
    // tateReconciler: autoMergeLevel2,
    // transforms: [transformCircular]
}

const reducersJson = {
    ...authReducers,
    ...componentsReducers,
    ...NotesReducers
}


const middlewares = [
    ...NotesMiddlewares
] 

export const reducers = persistCombineReducers(persistConfig,reducersJson)

export const persistedStore = configureStore({
    reducer:reducers,
    devTools: process.env.REACT_APP_DEBUG?.toLocaleLowerCase() === 'true',
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}).concat(middlewares),
})

export const store = configureStore({
    reducer:reducersJson,
    devTools: process.env.REACT_APP_DEBUG?.toLocaleLowerCase() === 'true',
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}).concat(middlewares),
})

export const persistor = persistStore(persistedStore)

export type RootState = ReturnType<typeof store.getState>

export function useReducers(){
    const dispatch = useDispatch()
    const newReducers :any= {...reducersJson}

    function clearAll(){
        Object.keys(newReducers).forEach((key:string) => {
            if(newReducers[key].clear){
                dispatch(newReducers[key].clear())
            }
        })
    }

    return {clearAll}
}
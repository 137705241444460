import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import GoogleButton from "../../../components/form/buttons/googleButton/GoogleButton.component";
import FloatingLabelInput from "../../../components/form/inputs/floatingLabelInput/FloatingLabelInput.component";
import FloatingLabelPasswordInput from "../../../components/form/inputs/floatingLabelPasswordInput/FloatingLabelPasswordInput.component";
import DefaultLayout from "../../../components/layouts/defaultLayout/DefaultLayout.component";
import useApi from "../../../hooks/api/useApi";
import useAuth from "../../../hooks/auth/useAuth";
import useUserDirectory from "../../../hooks/userDirectory/useUserDirectory";
import { useReducers } from "../../../reducers/reducers";
import ToastUtils from "../../../utils/toast/ToastUtils";
import StringValidator from "../../../utils/validator/StringValidator";

export default function RegisterPage(){
//   const router = useRouter();
  const auth = useAuth();
  const api = useApi();
  const {t} = useTranslation()
  const [firstName, setFirstName] = useState("");
  const [lastNames, setLastNames] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [formErrors,setFormErrors] = useState<string[]>([])
  const [loading,setLoading] = useState(false)
  const firstNameRef: any = useRef();
  const navigate = useNavigate()
  const reducers = useReducers()
  const directory = useUserDirectory()

  useEffect(() => {
    setShowPassword(false);
    setPassword("");
    firstNameRef.current?.focus();
    reducers.clearAll()
    directory.disconnect()
  }, []);

  const handleClickSignUp = async (e: any) => {
    e.preventDefault();
    if (!api.loading) {
      await setLoading(true)
      const errors = []

      if (StringValidator.emptyString(firstName)) {
        errors.push('firstName')
      }

      if (StringValidator.emptyString(lastNames)) {
        errors.push('lastNames')
      }

      if (!StringValidator.validateEmail(email)) {
        errors.push('email')
      }

      if (StringValidator.emptyString(password)) {
        errors.push('password')
      }
      setFormErrors(errors)

      if(errors.length>0){
        ToastUtils.showToast("error", { message: "Check the errors and try again!"})
        await setLoading(false)
        return;
      }

      const res = await api.post(
        'auth/signup',
        { email, password, firstName, lastNames },
        {crypt: true}
      );
      if(res.success){
        const { user, token } =res.data;
        await auth.login(user,token);
        navigate(user.hasPassword ? '/':'/set-password',{replace: true});
      }else if(res.errors){
        setFormErrors(res.errors)
        ToastUtils.showToast("error", { message: "Check the errors and try again!"})
        await setLoading(false)
      }
    }
  };

  return (
    <DefaultLayout>
      <div className="flex h-full w-full bg-body-public px-5 content-center items-center mt-14 md:mt-40">
      <div className="rounded-md container shadow-lg bg-white m-auto py-8 md:pt-14 px-5 md:px-10 max-w-full md:max-w-2xl border">
        <div>
          <h3 className="text-2xl font-bold">{t('SIGN_UP')}</h3>
          <p className="text-base text-gray-700">{t('TO_ACCESS_TO_YOUR_NOTES')}</p>
        </div>
        <form onSubmit={handleClickSignUp}>
          <div className="mt-10">
            <div className="md:flex">
              <FloatingLabelInput
                  value={firstName}
                  helperText="Enter your name"
                  error={formErrors.includes('firstName')}
                  label={t('NAME')}
                  className="md:mr-2"
                  inputRef={firstNameRef}
                  onChange={(e: any) => setFirstName(e.target.value)}
                />

              <FloatingLabelInput
                value={lastNames}
                helperText="Enter your last name"
                error={formErrors.includes('lastNames')}
                label={t('LAST_NAMES')}
                className="mt-4 md:mt-0 md:ml-2"
                onChange={(e: any) => setLastNames(e.target.value)}
              />
            </div>

            <div className="mt-4">
              <FloatingLabelInput
                value={email}
                helperText="Incorrect or existent email"
                error={formErrors.includes('email')}
                label={t('EMAIL')}
                onChange={(e: any) => setEmail(e.target.value)}
              />
            </div>

            <div className="mt-4">
              <FloatingLabelPasswordInput
                value={password}
                label={t('PASSWORD')}
                onChange={(e: any) => setPassword(e.target.value)}
                showPassword={showPassword}
                showPasswordFunction={() => setShowPassword(!showPassword)}
                error={formErrors.includes('password')}
                helperText="Incorrect or existent email"
              />
            </div>
          </div>
          <div className="mt-10">
            <button
              className="bg-secondary rounded w-full p-3 shadow-lg font-bold text-sm" 
              type="submit"
              disabled={loading}
            >
              {t('REGISTER')}
            </button>
          </div>
        </form>
        <div className="w-full flex justify-center">
          <GoogleButton />
        </div>
        <div className="mt-8 text-center">
          <Link
            className="text-sm no-underline text-gray-600 hover:underline cursor-pointer"
            to={'/login'}
          >
            {t('I_ALREADY_HAVE_AN_ACCOUNT')}
          </Link>
        </div>
      </div>
    </div>
    </DefaultLayout>
  );
}
import { IoDocumentLockOutline, IoDocumentTextOutline } from "react-icons/io5";
import { RiStarFill } from "react-icons/ri";
import { Link, useParams } from "react-router-dom";
import styles from "../EditorSidebar.module.css";
import { BiImage, BiImageAlt } from "react-icons/bi";

export default function NoteButton({ note, sidebar, childDeep = 0,...rest }: any) {
  const { token } = useParams();


  return (

<div {...rest} className={`${styles.buttonContainerFull}`}         
>
<Link
      to={`/note/${note.token}`}
      className={`block w-full cursor-pointer line-clamp-1 note ${
        sidebar.sidebar
          ? styles.directoryButtonFull
          : styles.directoryContainerIcon
      }
      
      ${note.token === token ? styles.noteOpened : ''}
              
      
              `}
      data-note={note.token}
      {...rest}
    >
      <div className="flex justify-end" style={{width:26,minWidth:26}}>
      {note.favorite && <RiStarFill size={9} color="ff9e0b"/>}
      {
        note.type === 1 ? 
          <BiImageAlt size={18}/>:
          (note.locked ? <IoDocumentLockOutline size={18}/>:<IoDocumentTextOutline size={17} style={{zIndex:1}}/>)
      }        

        
      </div>
      <div className="line-clamp-1" style={{ fontSize: 15 }}>
        {note.name}
      </div>
    </Link>


</div>
  );
}
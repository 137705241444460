import { t } from "i18next";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import DefaultLayout from "../../../components/layouts/defaultLayout/DefaultLayout.component";
import useAuth from "../../../hooks/auth/useAuth";
import useUserDirectory from "../../../hooks/userDirectory/useUserDirectory";
import styles from "./Home.module.css";

export function HomePage(){
    const directory = useUserDirectory()
    useEffect(() => {
        directory.disconnect()
    },[])

    const {t} = useTranslation()
    return (
       <DefaultLayout>
        <div className="md:flex justify-between w-full px-3 md:px-10 py-10 container mx-auto">
            <div className="xl:pt-5 md:w-1/2 md:mt-10">

                <h1 style={{fontSize:40, fontWeight: 'bold'}}>{t('HOME_HEADER')}</h1>
                <div className="text-gray-600 font-bold mt-5">{t('HOME_TEXT')}</div>
                <div className="flex md:block justify-center mb-10 md:mb-0">
                <Link to='/login'  
                className="mt-10 shadow-lg rounded flex items-center justify-center bg-secondary font-bold"
              style={{ height: 45, width: 250,fontSize:15,fontWeight: 'bolder'  }}>Create your free account</Link>
                </div>
            </div>
            <div className="flex justify-center md:w-1/2 md:py-10">
                <img src="videos/writeNote.gif" alt="person taking notes" style={{maxHeight:350}}/>
            </div>
        </div>

        
           
       </DefaultLayout>
    )
}
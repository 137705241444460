import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import axios from "axios";
import { useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux'
import { useNavigate } from "react-router-dom";
import { userReducer } from "../../reducers/auth/user/userReducer";
import { directorySocketReducer } from "../../reducers/notes/socket/directorySocketReducer";
import useApi, { apiInterceptor, API_URL } from "../api/useApi";

apiInterceptor()

export default function useAuth(){
    const REDUCER = userReducer
    const navigate = useNavigate()
    const user = useSelector((state:any) => state[REDUCER.name].value)
    const dispatch = useDispatch()

    function login(user:any, token:string){
        localStorage.setItem('access_token',token)
        setUser(user)
    }

    async function setUser(user:any){
        await dispatch({type: REDUCER.actions.login,payload: user})
    }

    async function logout(redirect = true){
        try {
            await axios.post(`${API_URL}/auth/logout`)
        } catch (error) {
            console.error("ERROR LOGOUT",error)
        }
        localStorage.removeItem('access_token')
        await dispatch({type: REDUCER.actions.logout})
        await dispatch({type: directorySocketReducer.actions.directorySocketDisconnect()})
        await dispatch({type: directorySocketReducer.actions.setDirectory({directory: null})})

        if(redirect){
            navigate('/login',{replace: true})
        }
    }

    async function setPasswordPageViewed(value:boolean){
        return localStorage.setItem('password_page_viewed',JSON.stringify(value))
    }

    async function getPasswordPageViewed(){
        const item = localStorage.getItem('password_page_viewed')
        return item ? JSON.parse(item):false
    }

    function getToken(){
        return localStorage.getItem('access_token')
    }

    return {
        user,
        setUser,
        login,
        logout,
        getToken,
        setPasswordPageViewed,
        getPasswordPageViewed
    }

}
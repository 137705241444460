import { createSlice, configureStore } from '@reduxjs/toolkit'

const REDUCER_KEY = 'COMPONENT_MOBILE_SIDEBAR'
export const sidebarMobileReducer = createSlice({
    name: REDUCER_KEY,
    initialState: {
        value: false
    },
    reducers: {
        show: (state) => {
            state.value = true
        },
        hide: (state) => {
            state.value = false
        }
    }
})
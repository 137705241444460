import React from "react"
import { BallTriangle, Grid, MutatingDots, RevolvingDot } from "react-loader-spinner"
import styles from './LoaderContainer.module.css'
type Props = {
    visible: boolean
}
export default function LoaderContainer({visible}:Props){

    

    return (
        <React.Fragment>
        {visible && <div
          className={styles.backdropMobile}
        />}

        {visible && 
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white z-50 rounded-lg px-5 py-5">
            <RevolvingDot color="black"/>
        </div>
        }

        </React.Fragment>
    )

}
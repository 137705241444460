import React from "react";

type Props = {
    open: boolean
    closeFunction: Function
    children?: any
    ref?:any
}

export default function DropdownMenu({open,closeFunction,children,ref,...rest}:any){
    return (
        <React.Fragment>
            <div style={{position: 'absolute',marginTop:80,marginLeft: 
            0, display: open ? 'block':'none'}} className="z-50 shadow-lg border border-gray-200 rounded py-2 bg-white" {...rest}>
                {children}
            </div>
        {open && 
            <div style={{position: 'absolute',left: 0,bottom: 0,right:0,top: 0}} onClick={closeFunction}></div>
        }
    </React.Fragment>
    )
    
}
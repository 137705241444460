export default class ThemeUtils{
  static defaultColors = {
    "--theme-primary": "#FFFFFF",
    "--theme-primary-text": "var(--theme-primary-text)",
    "--theme-primary-active-text": "var(--theme-primary-active-text)",
    "--theme-secondary": "#FFBD59",
    "--theme-secondary-text": "#000000",
    "--theme-secondary-active-text": "var(--theme-secondary-active-text)",
    "--theme-body": "#FFFFFF",
    "--theme-body-border": "var(--theme-body-border)",      

    "--theme-sidebar": "#F7F6F3",
    "--theme-sidebar-text": "#808080",
    "--theme-sidebar-active": "#F7F6F3",
    "--theme-sidebar-active-text": "#000000",

    "--theme-selector": "#BAD3FC",
    "--theme-selector-text": "#000000",

  };
  
  static applyTheme(theme: colorsTheme) {
    const root = document.documentElement;
    const newTheme :any= theme
    Object.keys(newTheme).forEach(cssVar => {
      root.style.setProperty(cssVar, newTheme[cssVar]);
    });
  }
}

type colorsTheme = {
  "--theme-primary": string
  "--theme-primary-text": string
  "--theme-primary-active-text": string
  "--theme-secondary": string
  "--theme-secondary-text": string
  "--theme-secondary-active-text": string
  "--theme-body": string
  "--theme-body-border": string
  "--theme-sidebar": string
  "--theme-sidebar-text": string
  "--theme-sidebar-active": string
  "--theme-sidebar-active-text": string
}
export default class StringValidator{
  static validateEmail(email: string) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  
  static stringHasLettersAndNumbers(string: string) {
    const re = /^(?=.*[a-zA-Z])(?=.*[0-9])/;
    return re.test(string);
  }
  
  static emptyString(string: string) {
    return !string || string.trim().length == 0;
  }
  
  static validateNamespace(string:string){
    const re = /^[a-z-0-9\\-\\_]{3,}$/
    return re.test(string) && string.length
  }
  
  static replaceNamespace(string:string){
    return string
    .toLowerCase()
    .replaceAll(" ", "_")
    .replaceAll("/", "_")
    .replaceAll(".", "")
    .replaceAll("#", "_")
    .replaceAll("&", "_").toLowerCase();
  }
  
  
  
  static validateUrl(string:string) {
    var re = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    return string && re.test(string)
  };
  
  static validatePostalCode(string:string) 
  {
    return /^\d{5}(-\d{4})?$/.test(string);
  }
  
  static validatePhoneNumber(string:string){
    const re = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
    return re.test(string)
  }
  
  static capitalizeString(string:string){
    let parts = ""
    const split = string.split(" ")
  
    split.forEach((word) => {
      if(word.length > 0){
        parts += word.charAt(0).toUpperCase()+word.slice(1).toLowerCase()+" "
      }
    })
  
  
    return parts
  }
}
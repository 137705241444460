import React from "react";
import Navbar from "../../navigation/header/navbar/Navbar.component";

type props = {
    className?:string
    children: React.ReactNode
}

export default function DefaultLayoutComponent({className = '',children}:props){


    return (
        <React.Fragment>
            <header>
                <Navbar />
            </header>
            <main className={className} style={{height:'100%'}}>
                {children}
            </main>
        </React.Fragment>
    )
}
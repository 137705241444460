import { useTranslation } from "react-i18next";
import { Middleware } from "redux";
import { Socket } from "socket.io-client";
import JsonUtils from "../../../utils/json/JsonUtils";
import { SocketUtils } from "../../../utils/socket/SocketUtils";
import ToastUtils from "../../../utils/toast/ToastUtils";
import {
  directoryActions,
  directorySocketReducer,
  setNoteName,
} from "./directorySocketReducer";

const directorySocketMiddleware: Middleware = (store) => {
  let socket: Socket | null = null;
  let socketEditNote: Socket | null = null;

  return (next) => async (action) => {
    const socketService = new SocketUtils(socket);
    const editNoteSocketService = new SocketUtils(socketEditNote);

    const isConnectionEstablished =
      socket && store.getState()[directorySocketReducer.name].isConnected
        ? true
        : false;

    if (directoryActions.startConnecting.match(action)) {
      store.dispatch(directoryActions.setIsEstablishingConnection());
      socket = await socketService.initSocket("user-directory");
      const res = await socketService.emit("join");
      // if(!res.success){
      //   // auth.logout()
      //   ToastUtils.showToast('error',{message: 'Alguna cosa ha anat malament!'})
      //   return;
      // }

      if (res.success) {
        socketService.on("joined", (data: any) => {
          console.log("new join", data);
        });

        socketService.on("disconnected", (data: any) => {
          console.log("new disconnected", data);
        });

        socketService.on("directory-change", (data: any) => {
          store.dispatch(
            directoryActions.setDirectory({ directory: data.directory })
          );
        });
        store.dispatch(directoryActions.connectionEstablished());
        store.dispatch(
          directoryActions.setDirectory({ directory: res.data.directory })
        );
        return res;
      }
    }

    if (socket && isConnectionEstablished) {
      //SOCKET DISCONNECT
      if (directoryActions.directorySocketDisconnect.match(action)) {
        await socketService.emit("user-disconnect");
        socketService.disconnect();
        socket = null;
      }

      //CREATE NEW FOLDER
      if (directoryActions.createNewFolder.match(action)) {
        const res = await socketService.emit("new-folder", action.payload);
        if (res.success) {
          store.dispatch(
            directoryActions.setDirectory({ directory: res.data.directory })
          );
        } else {
          if (res.userAuth) {
            ToastUtils.showToast("error", { message: res.message });
          } else {
            // ToastUtils.showToast('error',{message: t('SESSION_EXPIRED')})
            // auth.logout()
          }
        }
        return res;
      }

      //DELETE FOLDER
      if (directoryActions.deleteFolder.match(action)) {
        const res = await socketService.emit("delete-folder", action.payload);
        if (res.success) {
          store.dispatch(
            directoryActions.setDirectory({ directory: res.data.directory })
          );
        } else {
          ToastUtils.showToast("error", { message: res.message });
        }
        return res;
      }

      //CREATE NEW NOTE
      if (directoryActions.createNewNote.match(action)) {
        const res = await socketService.emit("new-note", action.payload);
        if (res.success) {
          store.dispatch(
            directoryActions.setDirectory({ directory: res.data.directory })
          );
        } else {
          ToastUtils.showToast("error", { message: res.message });
        }
        return res;
      }

      // DELETE NOTE
      if (directoryActions.deleteNote.match(action)) {
        const res = await socketService.emit("delete-note", action.payload);
        if (res.success) {
          store.dispatch(
            directoryActions.setDirectory({ directory: res.data.directory })
          );
        } else {
          ToastUtils.showToast("error", { message: res.message });
        }
        return res;
      }

      //FAVORITE NOTE
      if (directoryActions.setNoteFavoriteStatus.match(action)) {
        const res = await socketService.emit(
          "set-favorite-note",
          action.payload
        );
        if (res.success) {
          store.dispatch(
            directoryActions.setDirectory({ directory: res.data.directory })
          );
        } else {
          ToastUtils.showToast("error", { message: res.message });
        }
        return res;
      }
    }

    const isNoteEditConnectionEstablished =
      socketEditNote &&
      store.getState()[directorySocketReducer.name].noteTokenEditing
        ? true
        : false;

    //CONNECTION NOTE EDIT
    if (directoryActions.startNoteEditConnection.match(action)) {
      console.log("start connection");
      store.dispatch(directoryActions.setIsNoteEditEstablishingConnection());
      socketEditNote = await editNoteSocketService.initSocket("edit-note");
      const res :any= await editNoteSocketService.emit("join", action.payload);
      // if(!res.success){
      //   // auth.logout()
      //   ToastUtils.showToast('error',{message: 'Alguna cosa ha anat malament!'})
      //   return;
      // }

      if (res.success) {
        editNoteSocketService.on("joined", (data: any) => {
          console.log("new join", data);
        });

        editNoteSocketService.on("disconnected", (data: any) => {
          console.log("new disconnected", data);
        });

        editNoteSocketService.on("note-name-changed", (data: any) => {
          console.log("note name changed!!",data)
          store.dispatch(
            directoryActions.setEditingNoteName({
              name:data.note.name,
            })
          );
          store.dispatch(
            directoryActions.setEditNoteName({
              name:data.note.name,
              noteToken: data.note.token,
              sendToSocket: false
            })
          );
        });

        editNoteSocketService.on("note-content-changed", (data: any) => {
          console.log("note content change", data);
          // store.dispatch(
          //   directoryActions.setDirectory({ directory: data.directory })
          // );
        });

        console.log("connection", res);
        store.dispatch(
          directoryActions.setNoteTokenEditing({
            noteToken: action.payload.noteToken,
          })
        );
        store.dispatch(
          directoryActions.setEditingNoteName({
            name:res.data.note.name,
          })
        );
        store.dispatch(
          directoryActions.setEditingNoteContentBlocks({
            contentBlocks:res.data.note.contentBlocks,
          })
        );

        return res;
      }
    }

    if (
      socketEditNote &&
      isNoteEditConnectionEstablished &&
      editNoteSocketService
    ) {
      //NOTE

      //DISCONNECT NOTE EDIT
      if (directoryActions.editNoteSocketDisconnect.match(action)) {
        await editNoteSocketService.emit("user-disconnected");
        editNoteSocketService.disconnect();
        socketEditNote = null;
      }

      //CHANGE NOTE CONTENT
      if (
        directoryActions.setEditNoteContent.match(action) &&
        (typeof action.payload.sendToSocket == "undefined" ||
          action.payload.sendToSocket)
      ) {
        const res = await editNoteSocketService.emit(
          "change-content",
          action.payload
        );
        // const editingNote = res.data.note;
        // store.dispatch(directoryActions.setEditingNote({editingNote}));
      }

      //CHANGE NOTE NAME
      if (directoryActions.setEditNoteName.match(action)) {
        const newDirectory = JsonUtils.cloneJson(store.getState()[directorySocketReducer.name].directory)
        setNoteName(action.payload.noteToken,action.payload.name,newDirectory)
        store.dispatch(directoryActions.setDirectory({directory: newDirectory}))

        if((typeof action.payload.sendToSocket == "undefined" ||
        action.payload.sendToSocket)){
          const res = await editNoteSocketService.emit(
            "change-name",
            action.payload
          );
        }
      
        // const editingNote = res.data.note;
        // store.dispatch(directoryActions.setEditingNote({editingNote}));
      }
    }

    next(action);
  };
};

export default directorySocketMiddleware;

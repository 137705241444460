import CryptoJS from "crypto-js";

export default class CryptUtils{
  static encrypt(data: any, key :any= process.env.REACT_APP_CRYPT_KEY) {
    return CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
  }
  
  static decrypt(data: any, key :any= process.env.REACT_APP_CRYPT_KEY) {
    return JSON.parse(
      CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(data, key))
    );
  }
}

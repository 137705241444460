import { Menu, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineMenuAlt2, HiOutlineMenuAlt3 } from "react-icons/hi";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosStarOutline,
  IoMdLogOut,
} from "react-icons/io";
import { IoEllipsisHorizontalSharp, IoPeople } from "react-icons/io5";
import {
  RiChatHistoryLine,
  RiStarFill,
  RiStarLine,
  RiStarSFill,
} from "react-icons/ri";
import useAuth from "../../../../../hooks/auth/useAuth";
import { useEditorType } from "../../../../../hooks/editor/useEditor";
import useSidebar from "../../../../../hooks/sidebar/useSidebar";
import StringUtils from "../../../../../utils/string/StringUtils.utils";
import styles from "./EditorNavbar.module.css";

type props = {
  editor: useEditorType;
  showSidebar?: boolean;
};

export default function EditorNavbarComponent({
  showSidebar = true,
  editor,
}: props) {
  const {t} = useTranslation()
  const sidebar = useSidebar();
  const auth = useAuth();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function logout() {
    setAnchorEl(null);
    auth.logout();
  }

  const handleFavoriteClick = async () => {
    const newFavorite = editor.note.favorite ? false:true
    await editor.userDirectory.setNoteFavorite(editor.note.token,newFavorite)
  }

  return (
    <nav
      className={`flex w-100 pl-3 pr-3 lg:pl-0  ${styles.navbarContainer}`}
      style={{
        left: showSidebar ? sidebar.getCurrentMdWidth(true) : `0px`,
        height: sidebar.navbarHeight,
      }}
    >
      <div
        className={`flex items-center gap-5 mr-2 ${
          sidebar.sidebar ? "lg:mr-5" : "lg:mr-0"
        }`}
      >
        <button onClick={(e) => sidebar.changeSidebar()} className="lg:hidden">
          {sidebar.sidebar ? (
            <IoIosArrowBack
              size={23}
              color="var(--theme-sidebar-text)"
              style={{ cursor: "pointer" }}
            />
          ) : (
            <IoIosArrowForward
              size={23}
              color="var(--theme-sidebar-text)"
              style={{ cursor: "pointer" }}
            />
          )}
        </button>
      </div>
      <div className="flex-1 mr-10">
        <input
          value={editor.name ?? ''}
          readOnly={!editor.note.permission.canEdit}
          onInput={(e:any) => editor.note.permission.canEdit ? editor.changeNoteName(e.target.value):() => {}}
          placeholder={t('NOTE_NAME')}
          className="outline-none text-ellipsis whitespace-nowrap w-full"
        />
      </div>

      <div className={"flex gap-4"} style={{ height: 35 }}>
        {/* <button
          className="hidden md:flex items-center gap-2 text-sm px-3 bg-gray-100 rounded"
          title={t('SHARE_WITH_PEOPLE')}
        >
          <IoPeople size={18} className="text-gray-700" /> {t('SHARE')}
        </button>

        <button
          className="hidden md:flex text-sm px-2 hover:bg-gray-200 rounded items-center"
          title={t('CHANGES_HISTORY')}
        >
          <RiChatHistoryLine size={21} className="text-gray-700" />
        </button> */}

        <button
          className="hidden md:flex text-sm px-2 hover:bg-gray-200 rounded items-center"
          title={t('FAVORITE')}
          onClick={handleFavoriteClick}
        >
          {editor.note.favorite ? 
          <RiStarFill size={21} className="text-secondary"/>:
          <RiStarLine size={21} className="text-gray-600" />
          }
        </button>
        {/* <button
          className="text-sm px-2 hover:bg-gray-200 rounded"
          title={t('MORE_OPTIONS')}
        >
          <IoEllipsisHorizontalSharp size={21} className="text-gray-700" />
        </button> */}
      </div>
    </nav>
  );
}

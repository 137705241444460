import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import IconButton from '@mui/material/IconButton';
import {IoEyeOffSharp} from 'react-icons/io5'
import {IoIosEye} from 'react-icons/io'

export default function FloatingLabelPasswordInputComponent({
    helperText = "",
    error = false,
    showPassword = true,
    showPasswordFunction = function () {},
    label = "Password",
    rounded = true,
    onlyHelperTextOnError = true,
    fullWidth = true,
    className = '',
    size = "medium",
    ...rest
  }: any) {
  return (
    <TextField
      className={`${rounded ? 'defaultInputRounded':''} ${className}`}
      type={showPassword ? "text" : "password"}
      size={size}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" onClick={() => showPasswordFunction()} >
            <IconButton aria-label="toggle password visibility" edge="end">
              {showPassword ? <IoEyeOffSharp /> : <IoIosEye />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      fullWidth={fullWidth}
      label={label}
      error={error}
      helperText={!onlyHelperTextOnError || error ? helperText:''}
      {...rest}
    />
  );
}

import DefaultLayout from "../../../components/layouts/defaultLayout/DefaultLayout.component";
import styles from './Cookies.module.css'

export default function CookiesPage(){


    return (
        <DefaultLayout>
            <div className="container mt-5 mx-auto">
                <h2 className={styles.title}>Cookies and Web Beacons</h2>

            <p className="mt-3">
                Like any other website, webplam uses 'cookies'. These cookies are used
                to store information including visitors' preferences, and the pages on
                the website that the visitor accessed or visited. The information is
                used to optimize the users' experience by customizing our web page
                content based on visitors' browser type and/or other information.
            </p>

            <p className="mt-3"> 
                For more general information on cookies, please read{" "}
                <a className="text-blue-700 underline" href="https://www.privacypolicyonline.com/what-are-cookies/">
                "What Are Cookies"
                </a>
                .
            </p>

            <h2 className={`mt-5 ${styles.title}`}>Advertising Partners Privacy Policies</h2>

            <p className="mt-3">
                You may consult this list to find the Privacy Policy for each of the
                advertising partners of webplam.
            </p>

            <p className="mt-3">
                Third-party ad servers or ad networks uses technologies like cookies,
                JavaScript, or Web Beacons that are used in their respective
                advertisements and links that appear on webplam, which are sent directly
                to users' browser. They automatically receive your IP address when this
                occurs. These technologies are used to measure the effectiveness of
                their advertising campaigns and/or to personalize the advertising
                content that you see on websites that you visit.
            </p>

            <p className="mt-3">
                Note that webplam has no access to or control over these cookies that
                are used by third-party advertisers.
            </p>

            <h2 className={`mt-5 ${styles.title}`}>Third Party Privacy Policies</h2>

            <p className="mt-3">
                webplam's Privacy Policy does not apply to other advertisers or
                websites. Thus, we are advising you to consult the respective Privacy
                Policies of these third-party ad servers for more detailed information.
                It may include their practices and instructions about how to opt-out of
                certain options.{" "}
            </p>

            <p className="mt-3">
                You can choose to disable cookies through your individual browser
                options. To know more detailed information about cookie management with
                specific web browsers, it can be found at the browsers' respective
                websites.
            </p>

            <h2 className={`mt-5 ${styles.title}`}>CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>

            <p className="mt-3">
                Under the CCPA, among other rights, California consumers have the right
                to:
            </p>
            <p className="mt-3">
                Request that a business that collects a consumer's personal data
                disclose the categories and specific pieces of personal data that a
                business has collected about consumers.
            </p>
            <p className="mt-3">
                Request that a business delete any personal data about the consumer that
                a business has collected.
            </p>
            <p className="mt-3">
                Request that a business that sells a consumer's personal data, not sell
                the consumer's personal data.
            </p>
            <p className="mt-3">
                If you make a request, we have one month to respond to you. If you would
                like to exercise any of these rights, please contact us.
            </p>
        </div>

        
           
       </DefaultLayout>
    )
}
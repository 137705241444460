import axios from "axios"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { userReducer } from "../../reducers/auth/user/userReducer"
import CryptUtils from "../../utils/crypt/CryptUtils"
import ToastUtils from "../../utils/toast/ToastUtils"
import showToast from "../../utils/toast/ToastUtils"
import useApp from "../app/useApp"


export const API_URL :any= process.env.REACT_APP_API_URL

const defaultExtraOptions :ExtraOptionsType = {
    showErrorToast: true,
    crypt: false,
    autoLogout: true
}

apiInterceptor()

export default function useApi(){
    const [loading,setLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const app = useApp()
    const {t} = useTranslation()

    async function get(endpoint:string,params:any = {}, extraOptions:ExtraOptionsType = defaultExtraOptions){
        setLoading(true)
        let res :ResponseType = {
            success: false,
            errors: [],
            data: {},
            message: 'Error'
        }

        if(app.debug){
            console.group(`API GET >>> ${endpoint}`)
            console.log('PARAMS >>>',params)
        }

        if(extraOptions.crypt){
            params = {params: CryptUtils.encrypt(params)}
        }else{
            params = {params}
        }

        try {
            const newRes = await axios.get(`${API_URL}/${endpoint}`,{params})
            if(extraOptions.crypt){
                res = CryptUtils.decrypt(newRes.data.response)
            }else{
                res = newRes.data
            }

            if(app.debug){
                console.log('RESPONSE >>>',res)

            }

            if(extraOptions.showErrorToast && res.success == false){
                ToastUtils.showToast("error",{message: res.message})
            }
            
            if(extraOptions.autoLogout &&  res.success == false && res.data && res.data.success == false){
                localStorage.removeItem('access_token');
                await dispatch(userReducer.actions.logout())
                navigate('/login',{replace: true})
            }

        } catch (error) {
            if(app.debug){
                console.groupEnd()
            }
            console.error(`API GET ERROR ${endpoint}: `,error)
            ToastUtils.showToast("error",{message: 'Error, try again!'})
        }
        setLoading(false)
        return res


    }

    async function post(endpoint:string,params:any = {}, extraOptions:ExtraOptionsType = defaultExtraOptions){
        setLoading(true)
        let res :ResponseType = {
            success: false,
            errors: [],
            data: {},
            message: 'Error'
        }

        if(app.debug){
            console.group(`API POST >>> ${endpoint}`)
            console.log('PARAMS >>>',params)
        }

        if(extraOptions.crypt){
            params = {params: CryptUtils.encrypt(params)}
        }else{
            params = {params}
        }

        try {
            const newRes = await axios.post(`${API_URL}/${endpoint}`,params)
            if(extraOptions.crypt){
                res = CryptUtils.decrypt(newRes.data.response)
            }else{
                res = newRes.data
            }
            
            if(app.debug){
                console.log('RESPONSE >>>',res)
                console.groupEnd()
            }

            if(extraOptions.showErrorToast && res.success === false && (res.userAuth === false && extraOptions.autoLogout)){
                ToastUtils.showToast("error",{message: res.message})
            }

            if(extraOptions.autoLogout && res.userAuth === false){
                ToastUtils.showToast('error',{message: t('SESSION_EXPIRED')})
                localStorage.removeItem('access_token');
                await dispatch(userReducer.actions.logout())
                navigate('/login',{replace: true})
            }
        } catch (error) {
            if(app.debug){
                console.groupEnd()
            }            
            console.error(`API POST ERROR ${endpoint}: `,error)
            ToastUtils.showToast("error",{message: 'Error, try again!'})
        }
        setLoading(false)
        return res
    }


    return {
        get,
        post,
        loading
    }   
}

export type ResponseType = {
    success: boolean
    message:string
    data:any
    errors:any,
    userAuth?: boolean
}

type ExtraOptionsType = {
    showErrorToast?:boolean
    crypt?: boolean
    autoLogout?:boolean
}


export function apiInterceptor(){
    //GET
    axios.interceptors.request.use(
        config => {
            const token = localStorage.getItem('access_token')
            const locale = localStorage.getItem('locale') ?? 'en'
            if(!config.headers){
                config.headers = {}
            }
            if(token){
                config.headers.Authorization = `bearer ${token}`
            }
            config.headers['Accept-Language'] = locale;
            config.headers['App-Version'] = process.env.REACT_APP_VERSION ?? '';
            config.headers['Device-Key'] = process.env.REACT_APP_DEVICE_KEY ?? '';

            return config
        },
        error => {
            return Promise.reject(error)
        }
    )

    //POST
    axios.interceptors.response.use(
        response => {
            const token = localStorage.getItem('access_token')
            const locale = localStorage.getItem('locale') ?? 'en'
            if(!response.headers){
                response.headers = {}
            }
            if(token){
                response.headers.Authorization = `bearer ${token}`    
            }
            response.headers['Accept-Language'] = locale;
            response.headers['App-Version'] = process.env.REACT_APP_VERSION ?? '';
            response.headers['Device-Key'] = process.env.REACT_APP_DEVICE_KEY ?? '';
            return response
        },
        error => {
            return Promise.reject(error)
        }
    )
}
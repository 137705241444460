import React from "react";
import { useTranslation } from "react-i18next";
import { BsBackspace } from "react-icons/bs";
import { FiChevronLeft, FiSkipBack, FiTrash } from "react-icons/fi";
import { HiOutlineBackspace } from "react-icons/hi";
import styles from './ConfirmDialog.component.module.css'

type Props = {
  open: boolean,
  onAction?: Function
  onCancel?:Function
  title?: string|null
  text?: string|null
  showActionButton?:boolean
  showCancelButton?:boolean
  actionButtonText?:string|null
  cancelButtonText?:string|null
  type?: 'confirm' | 'delete'
  loading?:boolean
  CancelIcon?:any|'none'
  DeleteIcon?:any|'none'
  backOnDrop?:boolean
}

export default function ConfirmDialog({
  open,
  type = 'confirm',
  onAction = () => {},
  onCancel = () => {},
  title = null,
  text = null,
  showActionButton = true,
  showCancelButton = true,
  actionButtonText = null,
  cancelButtonText = null,
  loading = false,
  CancelIcon = null,
  DeleteIcon = null,
  backOnDrop = true

}:Props) {
  const {t} = useTranslation()
  if(!actionButtonText){
    actionButtonText = t(type === 'confirm' ? 'CONFIRM':'DELETE')
  }

  if(!text){
    text = t(type == 'confirm' ? 'CONFIRM_CONFIRMATION':'DELETE_CONFIRMATION')
  }

  if(!DeleteIcon && DeleteIcon!=='none'){
    DeleteIcon = FiTrash
  }

  if(!CancelIcon && CancelIcon!=='none'){
    CancelIcon = HiOutlineBackspace
  }

  return (
    <React.Fragment>
      {open && <div className={`${styles.backdrop}`} onClick={() => backOnDrop ? onCancel():() => {}}></div>}
      {open && (
        <div className="w-full max-w-lg p-5 mx-auto mt-40 rounded-xl shadow-lg bg-white object-center rounded-lg border fixed left-1/2 transform -translate-x-1/2" style={{zIndex:100}}>
        <div>
          <div className="text-center p-5 flex-auto justify-center">
            <h2 className="text-xl font-bold py-4 ">{title ?? t('ARE_YOU_SURE')}?</h2>
            <p className="text-sm text-gray-500 px-8">
              {text}
            </p>
          </div>
          <div className="p-3 mt-2 flex items-center justify-around">
            {showCancelButton && 
              <button disabled={loading} className="flex items-center gap-2 mb-2 md:mb-0 bg-white px-3 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100" onClick={() => onCancel()}>
                {CancelIcon && CancelIcon !== 'none' && <CancelIcon className="text-gray-500" size={18} />}
                {cancelButtonText ?? t('CANCEL')}
              </button>
            }

            {showActionButton && 
              <button disabled={loading} className={`flex items-center gap-2 mb-2 md:mb-0 border px-3 py-2 text-sm shadow-sm font-medium tracking-wider rounded-full hover:shadow-lg ${type == 'confirm' ? 'bg-secondary text-secondary-text':'hover:bg-red-700 bg-red-600 border-red-600 text-white'}`} onClick={() => onAction()}>
                {DeleteIcon && DeleteIcon !== 'none' && <DeleteIcon className="text-white" size={16}/>}
                {actionButtonText}
              </button>
            }
            
          </div>
        </div>
      </div>
      )}

    </React.Fragment>
  );
}



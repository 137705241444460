import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useApi from "../../../../hooks/api/useApi";
import useAuth from "../../../../hooks/auth/useAuth";
import { GoogleLogin, hasGrantedAnyScopeGoogle } from '@react-oauth/google';

const KEY: any = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID;

export default function GoogleButtonComponent() {
  const auth = useAuth();
  const api = useApi();
  const navigate = useNavigate()
  const {t} = useTranslation()
  let loading = false

  async function success(e: any) {
    if (!api.loading && !loading) {
      loading = true
      const params = { credential: e.credential };
      const res = await api.post('auth/sign-with-google',params,{crypt: true,autoLogout: false});
      
      if (res.success) {
        const { user, token } =res.data;
        await auth.login(user,token);
        navigate(user.hasPassword ? '/':'/set-password',{replace: true});
      }
      loading = false
    }
  }

  async function error(){
    console.error("FAILURE GOOGLE BUTTON")
  }

  return (
    <div className="mt-10">
      <GoogleLogin
        useOneTap={true}
        onSuccess={success}
        onError={error}
      />
    </div>
  );
}

import axios from "axios";
import { useNavigate } from "react-router-dom";
import CryptUtils from "../../utils/crypt/CryptUtils";
import useApi, { apiInterceptor, API_URL } from "../api/useApi";
import useAuth from "../auth/useAuth";

apiInterceptor();

export default function useApp() {
  const auth = useAuth();
  const navigate = useNavigate();
  const debug = process.env.REACT_APP_DEBUG === "true" ? true : false;

  async function initApp() {
    try {
        const accessToken = localStorage.getItem("access_token");
        if (!accessToken) {
        return false;
        }

        const res = await axios.post(`${API_URL}/auth/autologin`);
        const decrypted = CryptUtils.decrypt(res.data.response);
        if (!decrypted.success) {
            auth.logout();
            return false;
        }

        const { user, token } = decrypted.data;
        await auth.login(user, token);

        return true;
    } catch (error) {
        console.log('AUTOLOGIN ERROR',error)
        auth.logout();
        return false;
    }
  }

  return {
    initApp,
    debug,
  };
}

import { Navigate,Outlet } from 'react-router-dom';
import PageNotFoundPage from '../pages/errors/pageNotFound/PageNotFound.page';
import NotePage from '../pages/private/note/Note.page';
import NotesPage from '../pages/private/notes/Notes.page';
import SetPasswordPage from '../pages/private/setPassword/SetPassword.page';
import { SettingsPage } from '../pages/private/settings/Settings.page';
import CookiesPage from '../pages/public/cookies/Cookies.page';
import { HomePage } from '../pages/public/home/Home.page';
import SignInPage from '../pages/public/login/Login.page';
import SignUpPage from '../pages/public/register/Register.page';


const PATH_REDIRECTING_TO_LOGIN = '/login'
const PATH_REDIRECTING_TO_APP = '/notes'

const routes = (isLoggedIn:boolean) => [
  // {
  //   path: '/app',
  //   // element: isLoggedIn ? null : <Navigate to="/signin" />,
  //   children: isLoggedIn ? [
  //     { path: 'notes', element: <NotesPage /> },
  //     { path: 'settings', element: <SettingsPage /> },
  //     { path: '', element: <Navigate to="/app/dashboard" /> },
  //   ]:
  //   [{ path: '*', element: <Navigate to="/signin" /> }],
    
  // },
  {
    path: '/',
    // element: !isLoggedIn ? <HomePage /> : <Navigate to="/app/dashboard" />,
    children: [
      { path: 'login', element: !isLoggedIn ? <SignInPage />: <Navigate to={PATH_REDIRECTING_TO_APP} />},
      { path: 'register', element:!isLoggedIn ?  <SignUpPage />: <Navigate to={PATH_REDIRECTING_TO_APP} /> },
      { path: 'notes', element: isLoggedIn ? <NotesPage />:<Navigate to={PATH_REDIRECTING_TO_LOGIN} /> },
      { path: 'notes/:folderToken', element: isLoggedIn ? <NotesPage />:<Navigate to={PATH_REDIRECTING_TO_LOGIN} /> },
      { path: 'set-password', element: isLoggedIn ? <SetPasswordPage />:<Navigate to={PATH_REDIRECTING_TO_LOGIN} /> },
      { path: 'note/:token', element: isLoggedIn ? <NotePage />:<Navigate to={PATH_REDIRECTING_TO_LOGIN} /> },
      { path: 'settings', element: isLoggedIn ? <SettingsPage />:<Navigate to={PATH_REDIRECTING_TO_LOGIN} /> },
      { path: 'cookies', element: <CookiesPage />},
      { path: '', element: isLoggedIn ? <NotesPage />:<HomePage /> },
      
    ],
  },
  {
    path: '*',
    element: <PageNotFoundPage />
  }
];

export default routes;
import {
  MdOutlineNotificationsActive,
  MdOutlineArchive,
  MdOutlinePushPin,
  MdLockOutline,
} from "react-icons/md";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { Link } from "react-router-dom";
import styles from "./note.module.css";
import { RiStarFill, RiStarLine } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import useUserDirectory from "../../../../../hooks/userDirectory/useUserDirectory";

type props = {
  name: string;
  content?: string;
  lastModified?: string;
  contentLineClam?: number;
  nameLineClam?: number;
  noteToken: string;
  canDrag?: boolean;
  style?: any;
  titleSize?:number
  favorite?: boolean,
  setLoading?:Function
};

export default function NoteComponent({
  name,
  content,
  lastModified,
  contentLineClam = 4,
  nameLineClam = 3,
  noteToken,
  canDrag = true,
  titleSize = 14,
  style = {},
  favorite = false,
  setLoading = (val:boolean) => {},
  ...rest
}: props) {
  const {t} = useTranslation()
  const userDirectory = useUserDirectory()

  let item :any= null
  let oldBodyScroll :any= null
  function handleDrag(e: any) {
    const selectedItem = e.target,
      list = selectedItem.parentNode,
      x = e.clientX,
      y = e.clientY;

    selectedItem.classList.add(styles.dragSortActive);
    let swapItem =
      document.elementFromPoint(x, y) === null
        ? selectedItem
        : document.elementFromPoint(x, y);

    if (list === swapItem.parentNode) {
      swapItem =
        swapItem !== selectedItem.nextSibling ? swapItem : swapItem.nextSibling;
      list.insertBefore(selectedItem, swapItem);
    }
     item.style.top = e.pageY+'px'
     item.style.left = e.pageX+'px'
  }

  function handleDrop(e: any) {
    if (canDrag) {
      e.target.classList.remove(styles.dragSortActive);
    }

    document.body.removeChild(item)
    document.body.style.overflowX = oldBodyScroll
    item = null
  }

  function onDragStart(e:any){
    item = e.target.cloneNode(true)
    item.style.position = 'absolute'
    item.style.top = e.target.pageY+'px'
    item.style.left = e.target.pageX+'px'
    item.style.width = e.target.offsetWidth+'px'
    item.style.height = e.target.offsetHeight+'px'
    e.dataTransfer.setDragImage(new Image(), 0, 0);
    setTimeout(() => {
      document.body.appendChild(item);
      oldBodyScroll =  document.body.style.overflowX
      document.body.style.overflowX = 'hidden'
    },100)
  }

  function onDragOver(e:any){
    e.preventDefault()
    e.dataTransfer.effectAllowed = "move";
  }

  const handleFavorite = async () => {
    setLoading(true)
    await userDirectory.setNoteFavorite(noteToken,!favorite)
    setLoading(false)
  }


  return (
    <Link
      to={`/note/${noteToken}`}
      data-note={noteToken}
      className={`px-3 py-3 border rounded-lg relative note ${styles.grabbing} hover:shadow bg-white`}
      style={{ paddingBottom: lastModified ? 47 : 60, ...style }}
      draggable={canDrag}
      onDrag={handleDrag}
      onDragEnd={handleDrop}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
    >
      <div
        className={`${styles.link} block h-full`}
        draggable={false}
      >
        <div
          className={`font-bold ${
            lastModified ? "line-clamp-1" : "line-clamp-2"
          } ${styles.name}`}
          style={{fontSize:titleSize}}>
          {name}
        </div>
        {content ?
           <div
           className={`mt-2 ${lastModified ? "line-clamp-1" : "line-clamp-2"} ${
             styles.name
           }`}
         >
           <div style={{lineHeight:1,maxHeight:120}} dangerouslySetInnerHTML={{__html:content}}></div>
         </div>:<div className="mb-3"></div>
        }
      </div>
      <div
        className={`left-0 bottom-0 right-0 px-4 absolute`}
        style={{ paddingBottom:lastModified ?1:15 }}
        onClick={(e: any) => e.preventDefault()}
        draggable={false}
      >
        {lastModified ? (
          <div className="font-bold text-gray-600 mb-3 text-right" style={{fontSize:10}}>
            Edited {lastModified}
          </div>
        ) : (
          <div className="flex justify-between items-center">
            {/* <button
              onClick={(e: any) => e.preventDefault()}
            >
              <MdOutlineNotificationsActive className="pointer-events-none" size={16} />
            </button> */}
            <button title={t(favorite ? 'UN_MARK_AS_FAVORITE':'MARK_AS_FAVORITE')} onClick={handleFavorite}>
            {favorite ? 
              <RiStarFill size={18} className="text-secondary"/>:
              <RiStarLine size={18} className="text-gray-600" />
              }
            </button>
            {/* <button name={t('LOCK_NOTE')}>
              <MdLockOutline className="pointer-events-none" size={18} />
            </button> */}
            {/* <button>
              <BiDotsVerticalRounded className="pointer-events-none" size={19} />
            </button> */}
          </div>
        )}
      </div>
    </Link>
  );
}

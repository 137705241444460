import { FormEvent, useEffect, useRef, useState } from "react";
import { IoChevronDown, IoChevronForward, IoSearch } from "react-icons/io5";
import { Link } from "react-router-dom";
import useApi from "../../../hooks/api/useApi";
import StringUtils from "../../../utils/string/StringUtils.utils";
import DefaultDialog from "../../parts/dialogs/defaultDialog/DefaultDialog";

type Props = {
  open?: boolean;
  closeSearchFunction?: Function;
};

export default function SearchContainer({
  open = false,
  closeSearchFunction = () => {},
}: Props) {
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [notes, setNotes] = useState<any[]>([]);
  const inputRef = useRef<null | HTMLInputElement>(null);
  type LastNote = {
    token: string;
    searchOpened: boolean;
  };
  const [lastNotes, setLastNotes] = useState<LastNote[]>([]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 100);
    }
  }, [open]);

  useEffect(() => {
    let delayDebounceFn: any = null;
    if (!loading && searchTerm.length > 0) {
      delayDebounceFn = setTimeout(() => {
        searchNote();
        // Send Axios request here
      }, 300);
    } else {
      setNotes([]);
    }
    return () => {
      if (delayDebounceFn) {
        clearTimeout(delayDebounceFn);
      }
    };
  }, [searchTerm]);

  function closeSearch(){
    closeSearchFunction()
  }

  async function searchNote() {
    setLoading(true);
    const res = await api.post("notes/search-notes", { searchTerm });
    if (res.success) {
      const newLastNotes = [...lastNotes];
      const subStringNotes = res.data.notes.map((note: any) => {
        let openedStatus = true;
        let lastNote = null;
        if (notes && notes.length > 0) {
          lastNote = lastNotes.find((last) => last.token === note.token);
        }

        if (lastNote) {
          openedStatus = lastNote.searchOpened;
        } else {
          newLastNotes.push({
            token: note.token,
            searchOpened: openedStatus,
          });
        }

        const modifiedNote = {
          textAppearances: note.contentBlocks
            ? StringUtils.editorBlockSubstring(
                note.contentBlocks.blocks,
                searchTerm
              )
            : [],
          searchOpened: openedStatus,
          nameContainsTerm: note.name ? note.name.toLowerCase().includes(searchTerm.toLocaleLowerCase()) : false,
          nameParts: [],
          nameIsNull:
            !note.name || note.name.trim().length === 0 ? true : false,
          ...note,
        };

        if (note.name && note.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          modifiedNote.nameParts = StringUtils.getHighlightedText(
            note.name,
            searchTerm
          );
        } else {
          modifiedNote.nameParts.push({
            highlight: false,
            text: modifiedNote.nameIsNull ? "Note Without name" : note.name,
          });
        }
        return modifiedNote;
      });
      setLastNotes(newLastNotes);
      setNotes(subStringNotes);
    }
    setLoading(false);
  }

  function handleChangeOpen(token: string) {
    const newNotes = [...notes];
    const changedNote = newNotes.find((note: any) => note.token == token);
    if (changedNote) {
      changedNote.searchOpened = !changedNote.searchOpened;
      const newLastNotes = [...lastNotes];
      const lastNote = newLastNotes.find((item) => item.token == token);
      if (lastNote) {
        lastNote.searchOpened = changedNote.searchOpened;
      } else {
        newLastNotes.push({
          token,
          searchOpened: changedNote.searchOpened,
        });
      }
      setNotes(newNotes);
      setLastNotes(newLastNotes);
    }
  }

  return (
    <DefaultDialog
      open={open}
      closeIcon={false}
      className="px-0 py-0 text-gray-800"
      closeFunction={closeSearch}
    >
      <div className="flex gap-2 items-center text-sidebar-text border-b px-3 py-3">
        <IoSearch size={19} />
        <input
          ref={inputRef}
          value={searchTerm}
          className="outline-none w-full"
          placeholder="Search notes by name or content"
          onInput={(e) => setSearchTerm(e.currentTarget.value)}
        />
      </div>
      {searchTerm && searchTerm.length > 0 && (
        <div className="py-3 px-3 break-words">
          {notes.length > 0 &&
            notes.map((note) => (
              <div className="text-sm bottom-1 py-1" key={note.token}>
                <div className="flex items-center">
                  <div style={{ width: 25 }}>
                    {note.textAppearances.length > 0 && (
                      <button
                        className="block"
                        style={{ width: 25 }}
                        onClick={(e: any) => handleChangeOpen(note.token)}
                      >
                        {note.searchOpened ? (
                          <IoChevronDown size={17} color="gray" />
                        ) : (
                          <IoChevronForward size={17} color="gray" />
                        )}
                      </button>
                    )}
                  </div>
                  <Link
                    onClick={() => closeSearch()}
                    to={`/note/${note.token}`}
                    className={`block font-semibold text-base`}
                  >
                    {note.nameParts.map((part: any, index: number) => (
                      <span
                        key={index}
                        style={{
                            backgroundColor: part.highlight
                              ? "rgba(255, 189, 89,0.5)"
                              : "",
                          }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </Link>
                </div>
                {note.searchOpened && (
                  <div style={{ paddingLeft: 25 }}>
                    {note.textAppearances.map(
                      (appearance: any, index: number) => (
                        <Link
                          onClick={() => closeSearch()}
                          to={`/note/${note.token}`}
                          className="block truncate py-1"
                          key={index}
                        >
                          {appearance.textAppearances.map(
                            (part: any, index: number) => (
                              <span
                                key={index}
                                style={{
                                  backgroundColor: part.highlight
                                    ? "rgba(255, 189, 89,0.5)"
                                    : "",
                                }}
                              >
                                {part.text}
                              </span>
                            )
                          )}
                        </Link>
                      )
                    )}
                  </div>
                )}
              </div>
            ))}
          {notes.length === 0 && !loading && (
            <div className="text-red-500 text-sm">
              Not found any note with this content or name
            </div>
          )}
        </div>
      )}
    </DefaultDialog>
  );
}

import { Link } from "react-router-dom";

export function SettingsPage(){
    return (
        <div>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <p>SETTING</p>
            <Link to={'/app'}>NOTES</Link>
        </div>
    )
}
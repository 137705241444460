import "react-toastify/dist/ReactToastify.min.css";
import React from "react";
import ReactDOM from "react-dom/client";
import "./drawer.css";
import "./editor.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { StyledEngineProvider } from "@mui/material/styles";
import { Provider } from 'react-redux'
import { store,persistor } from "./reducers/reducers";
import { PersistGate } from 'redux-persist/integration/react'

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
    <StyledEngineProvider injectFirst>
      <Provider store={store}>
        {/* <PersistGate loading={null} persistor={persistor}> */}
        <BrowserRouter>
          <App />
          <ToastContainer />
        </BrowserRouter>
        {/* </PersistGate> */}
      </Provider>
    </StyledEngineProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import directorySocketMiddleware from "./socket/directorySocketMiddleware";
import { directorySocketReducer } from "./socket/directorySocketReducer";


export const NotesReducers = {
    [directorySocketReducer.name]: directorySocketReducer.reducer
}

export const NotesMiddlewares = [
    directorySocketMiddleware
]
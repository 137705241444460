/* eslint-disable */
import Embed from '@editorjs/embed'
import List from '@editorjs/list'
import Warning from '@editorjs/warning'
import Code from '@editorjs/code'
import LinkTool from '@editorjs/link'
import Header from '@editorjs/header'
import Quote from '@editorjs/quote'
import Marker from '@editorjs/marker'
import CheckList from '@editorjs/checklist'
import Delimiter from '@editorjs/delimiter'
import InlineCode from '@editorjs/inline-code'
import SimpleImage from '@editorjs/simple-image'
import SimpleImageBlob from 'simple-image-editorjs'
import ColorPlugin from 'editorjs-text-color-plugin'
import FontFamily from 'editorjs-inline-font-family-tool'
import FontSize from 'editorjs-inline-font-size-tool'
import Underline from '@editorjs/underline';
import TextSpolier  from 'editorjs-inline-spoiler-tool';
import Alert from 'editorjs-alert';
import ChangeCase from 'editorjs-change-case';
import Tooltip from 'editorjs-tooltip';
import NestedList from '@editorjs/nested-list';
import Paragraph from 'editorjs-paragraph-with-alignment'
import AttachesTool from '@editorjs/attaches'
import Table from 'editorjs-table'
import CodeTool from '@editorjs/code'
import AnyButton from 'editorjs-button'
//AUTOCOMPLETE https://github.com/editor-js/link-autocomplete
import editorjsCodeflask from '@calumk/editorjs-codeflask';
import ImageTool from '@editorjs/image';
import Strikethrough from '@sotaproject/strikethrough';
// import ToggleBlock from 'editorjs-toggle-block';
// import EJLaTeX from 'editorjs-latex'
import { API_URL } from '../../hooks/api/useApi'
// import TextVariantTune from '@editorjs/text-variant-tune';
import { FaPooStorm } from 'react-icons/fa'
import axios from 'axios'
import JsonUtils from '../../utils/json/JsonUtils'
export function getEditorJsTools(noteToken){
  return {
    embed: { // EMBED CONTENT LIKE YT VIDEOS
      class: Embed,
      inlineToolbar: true
    },
    table: Table, // TABLE
    code: { // CODE
      class: CodeTool,
      shortcut: 'CMD+SHIFT+S',
    },
    list: { // ORDERED LIST AND NESTED LIST
      class: NestedList,
      inlineToolbar: true,
    },
    paragraph: { // PRARPGRAPH
      class: Paragraph,
      inlineToolbar: true,
    },
    strikethrough: Strikethrough, //THROUGHT LINE TEXT
    header: Header, // HEADER
    checklist: { // CHECKLIST
      class: CheckList,
      shortcut: 'CMD+SHIFT+C',
    },
    delimiter: { // SEPARATION LINE
      class: Delimiter,
      shortcut: 'CMD+SHIFT+D',
    },
    inlineCode: InlineCode, // CODE
    underline: Underline, // UNDERLINE
    tooltip: { // LINE TOOLTIP
      class: Tooltip,
      shortcut: 'CMD+SHIFT+P',
      inlineToolbar: true,
      config: {
        location: 'top',
        highlightColor: '#FFEFD5',
        underline: true,
        backgroundColor: '#154360',
        textColor: '#FDFEFE',
        holder: 'editor',
      }
    },
    alert: { // ALERTS
      class: Alert,
      inlineToolbar: true,
      shortcut: 'CMD+SHIFT+A',
      config: {
        defaultType: 'danger',
        messagePlaceholder: 'Enter something',
      },
    },
    changeCase: { // CHANGE CASE
      class: ChangeCase,
    },
    fontSize: FontSize, // FONTSIZE
    Color: {
      class: ColorPlugin, // COLOR
      config: {
         defaultColor: '#000000',
         type: 'text', 
         customPicker: true,
      }     
    },
    Marker: {
      class: ColorPlugin, // BACKGROUND MARKER
      shortcut: 'CMD+SHIFT+M',
      config: {
          customPicker: true,
         defaultColor: '#FFF000',
         type: 'marker', 
      }       
    },
    AnyButton: { // BUTTON
      class: AnyButton,
      inlineToolbar: true,
    },
    attaches: {
      class: AttachesTool,
      config: {
        // endpoint: `${API_URL}/notes/upload-file-note`,
        // additionalRequestHeaders: {
        //   "App-Version": process.env.REACT_APP_VERSION ?? '',
        //   "Device-Key": process.env.REACT_APP_DEVICE_KEY ?? ''
        // }
        uploader: {
          async uploadByFile(file){
            try{
              const data = new FormData()
              data.append('file',file)
              const dataParams = {
                params: {noteToken}
              }
              data.append('data',JSON.stringify(dataParams))
              const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
              }
              const res = await axios.post(`${API_URL}/notes/upload-file-note`,data,config)
              return res.data
            }catch(err){
              return {}
            }
          }
        }
      }
    },
    // MEMORY LIMIT
   simpleImage: SimpleImage,
  
    //textVariant: TextVariantTune // NOT INTERESSED, ALERT BETTER
    // fontFamily: FontFamily, // NOT INTERESSED
    // image: { // NOT DRAGGABLE
    //   class: ImageTool,
    //   config: {
    //     uploader: {
    //       uploadByFile(file){
    //         // your own uploading logic here
    //         console.log("loaded by file")
    //       },
    //       uploadByUrl(url){
    //         // your ajax request for uploading
    //         console.log("loaded by url")
    //       }
    //     }
    //   }
    // }
    // warning: Warning, // ERRORS
    // quote: Quote, USELESS
    // TextSpolier: TextSpolier, // ERRORS
    // simpleImage: SimpleImageBlob NOT WORKING
    // BUG TOGGLE ERRORS I PADDING HORITZONTAL
    // toggle: {
    //   class: ToggleBlock,
    //   inlineToolbar: true,
    // },
    // ERROR LINK ALWAYS WRONG
    //linkTool: LinkTool,
    // NOT WORKING
    // Math: {
    //   class: EJLaTeX,
    //   shortcut: 'CMD+SHIFT+H',
    //   inlineToolbar: true,
    // }
  }
}
import TextField from "@mui/material/TextField";
import styles from './FloatingLabelInput.module.css'

export default function FloatingLabelInputComponent({
  error = false,
  label = "Introdueix",
  helperText = "",
  onlyHelperTextOnError = true,
  fullWidth = true,
  size = 'medium',
  rounded = true,
  className = '',
  ...rest
}: any) {
  return (
    <TextField
      error={error}
      label={label}
      helperText={!onlyHelperTextOnError || error ? helperText:''}
      fullWidth={fullWidth}
      size={size}
      className={`${rounded ? 'defaultInputRounded':''} ${className}`}
      {...rest}
    />
  );
}

import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import {
  exportToCanvas,
  exportToSvg,
  exportToBlob,
  exportToClipboard,
  Excalidraw,
  useHandleLibrary,
  MIME_TYPES,
  sceneCoordsToViewportCoords,
  viewportCoordsToSceneCoords,
  restoreElements,
  LiveCollaborationTrigger,
  MainMenu,
  Footer,
  Sidebar
} from "@excalidraw/excalidraw";
import {
  AppState,
  BinaryFileData,
  ExcalidrawImperativeAPI,
  ExcalidrawInitialDataState,
  Gesture,
  LibraryItems,
  PointerDownState as ExcalidrawPointerDownState,
  BinaryFiles
} from "@excalidraw/excalidraw/types/types";

import styles from "./DrawEditor.module.css";

import { NonDeletedExcalidrawElement } from "@excalidraw/excalidraw/types/element/types";
import { MdDarkMode, MdGridOff, MdGridOn, MdLightMode, MdOutlineFormatColorFill } from "react-icons/md";
import backgroundColors from './backgroundColors.json'
import useWindowSize from "../../../../../hooks/window/useWindowSize";
import DefaultEditLayout from "../../../../../components/layouts/defaultEditLayout/DefaultEditLayout.layout";
import PopoverMenuContainer from "../../../../../components/containers/popoverMenu/PopoverMenuContainer";
import _, { debounce } from "lodash";
import JsonUtils from "../../../../../utils/json/JsonUtils";

type ExcalidrawData = {
  theme: 'light' | 'dark'
  viewModeEnabled: boolean
  zenModeEnabled: boolean
  gridModeEnabled: boolean
  exportEmbedScene: boolean
  data: {
    elements: any[],
    appState: {
      viewBackgroundColor: string
      currentItemFontFamily: number
    },
    files: any,
    scrollToContent: boolean,
    libraryItems: any[]
  }
}


export default function DrawEditor({editor,loading,setLoading}:any) {
  const size = useWindowSize()
  const backgroundPickerButtonRef = useRef(null)
  const [loadingSave,setLoadingSave] = useState(false)
  const [excalidrawData,setExcalidawData] = useState<ExcalidrawData>(editor.note.contentBlocks && editor.note.contentBlocks.data ? JsonUtils.cloneJson(editor.note.contentBlocks):{
    data: {
      elements: [],
      appState: {
        viewBackgroundColor: backgroundColors[0].color,
        currentItemFontFamily: 1,
        exportEmbedScene: false,
        exportWithDarkMode: true,
      },
      files: {},
      scrollToContent: true,
      libraryItems: []
    },
    viewModeEnabled: false,
    zenModeEnabled: false,
    gridModeEnabled: false,
    theme: 'light'
  })

  const [
    excalidrawAPI,
    setExcalidrawAPI
  ] = useState<ExcalidrawImperativeAPI | null>(null);

  useHandleLibrary({ excalidrawAPI });


  function changeBackgroundColor(color:string){
    if(!excalidrawAPI){
      return
    }

    const newExcalidrawData :any= JsonUtils.cloneJson(excalidrawData)
    newExcalidrawData.data.elements = getSceneElements()
    newExcalidrawData.data.files = getFiles()
    newExcalidrawData.data.appState.viewBackgroundColor = color
    excalidrawAPI.updateScene(newExcalidrawData.data)
    setExcalidawData(newExcalidrawData)
  }

  function changeGridOption(){
    const newExcalidrawData = JsonUtils.cloneJson(excalidrawData)
    newExcalidrawData.gridModeEnabled = !excalidrawData.gridModeEnabled
    setExcalidawData(newExcalidrawData)
  }

  const renderTopRightUI = (isMobile: boolean) => {
    return (
      <>
       <button
            ref={backgroundPickerButtonRef}
            className="library-button"
            title="Change the background color"
          >
            <MdOutlineFormatColorFill />
          </button>
         <PopoverMenuContainer refOpenButtons={[backgroundPickerButtonRef]}>
            <div className={styles.buttonsColorMenuContainer}>
            {backgroundColors.map((color,index) => (
              <button 
              title={color.name}
              onClick={(e:any) => changeBackgroundColor(color.color)} key={index} 
              className={`${styles.buttonColor}`} 
              style={{
                background: !color.image ? color.color:`url(${color.image}) left center`,
              }}>

              </button>
            ))}
            </div>
         </PopoverMenuContainer>

        <button
            onClick={() => changeTheme()}
            className="library-button"
            title={`Enable the ${excalidrawData.theme === "dark" ? "light": "dark"} mode`}
          >
            {excalidrawData.theme === "dark" ? <MdLightMode />:<MdDarkMode />}
          </button>
        <button
          onClick={() => changeGridOption()}
          className="library-button"
          title={`${excalidrawData.gridModeEnabled ? "Disable": "Enable"} the grid mode`}
        >
          {excalidrawData.gridModeEnabled ? <MdGridOff />:<MdGridOn />}
        </button>
      </>
    );
  };

  const onLinkOpen = useCallback(
    (
      element: NonDeletedExcalidrawElement,
      event: CustomEvent<{
        nativeEvent: MouseEvent | React.PointerEvent<HTMLCanvasElement>;
      }>
    ) => {
      const link = element.link!;
      const { nativeEvent } = event.detail;
      const isNewTab = nativeEvent.ctrlKey || nativeEvent.metaKey;
      const isNewWindow = nativeEvent.shiftKey;
      const isInternalLink =
        link.startsWith("/") || link.includes(window.location.origin);
      if (isInternalLink && !isNewTab && !isNewWindow) {
        // signal that we're handling the redirect ourselves
        event.preventDefault();
        // do a custom redirect, such as passing to react-router
        // ...
      }
    },
    []
  );

  const onCopy = async (type: "png" | "svg" | "json",data:any = null) => {
    if (!excalidrawAPI) {
      return false;
    }
    if(!data){
      data = {
        elements: excalidrawAPI.getSceneElements(),
        appState: excalidrawAPI.getAppState(),
        files: excalidrawAPI.getFiles(),
      }
    }

    data.type = type

    await exportToClipboard(data);
    // window.alert(`Copied to clipboard as ${type} successfully`);
  };

  function changeTheme() {
    const newExcalidrawData = JsonUtils.cloneJson(excalidrawData)
    newExcalidrawData.theme = excalidrawData.theme === "light" ? 'dark':'light'
    setExcalidawData(newExcalidrawData)
  }

  function getSceneElements(){
    if(!excalidrawAPI){
      return []
    }
    return JsonUtils.cloneJson( excalidrawAPI.getSceneElements())
  }

  function getFiles(){
    if(!excalidrawAPI|| loadingSave){
      return []
    }
    return JsonUtils.cloneJson( excalidrawAPI.getFiles())
  }

  async function saveFunction(){
    if(!excalidrawAPI ){
      return;
    }

    const elements = getSceneElements()
    const newExcalidrawData :any= JsonUtils.cloneJson(excalidrawData)
    const notFoundElement = elements.find((item:any) => {
        const foundElement = newExcalidrawData.data.elements.find((e:any) => {
          return JSON.stringify(e) === JSON.stringify(item)
        })
        return !foundElement
    })

    const files :any= getFiles()
    if((!notFoundElement && elements.length === newExcalidrawData.data.elements.length) 
    && JSON.stringify(files) === JSON.stringify(newExcalidrawData.data.files)
    ){
      return;
    }

    if(loadingSave){
      return
    }

    setLoadingSave(true)
    newExcalidrawData.data.elements = elements
    newExcalidrawData.data.files = files
    setExcalidawData(newExcalidrawData)
    const svg = await exportToSvg(newExcalidrawData.data);
    svg.style.width = '100%'
    svg.style.height = '100%'
    console.log("TO EDIT",newExcalidrawData)
    await editor.changeNoteContent(newExcalidrawData, svg.outerHTML,"", null);
    setLoadingSave(false)
  }
  const saveHandle = debounce(async () => {
    await saveFunction()
  },500)

  useEffect(() => {
    setLoading(false)
    setLoadingSave(false)
  },[])

  async function saveLibraryItems(libraryItems:any){
    // console.log("lib",libraryItems)
    // await saveFunction(libraryItems)
  }

  const renderMenu = () => {
    return (
      <MainMenu>
        <MainMenu.DefaultItems.SaveAsImage />
        <MainMenu.DefaultItems.LoadScene />
        <MainMenu.DefaultItems.ClearCanvas />
        <MainMenu.DefaultItems.Export />
        <MainMenu.Separator />
        {/* <MainMenu.DefaultItems.LiveCollaborationTrigger
          isCollaborating={isCollaborating}
          onSelect={() => window.alert("You clicked on collab button")}
        /> */}
        {/* <MainMenu.Group title="Excalidraw links">
          <MainMenu.DefaultItems.Socials />
        </MainMenu.Group>
        <MainMenu.Separator />
        <MainMenu.ItemCustom>
          <button
            style={{ height: "2rem" }}
            onClick={() => window.alert("custom menu item")}
          >
            custom item
          </button>
        </MainMenu.ItemCustom> */}
        <MainMenu.DefaultItems.Help />

      </MainMenu>
    );
  };
  return (
<DefaultEditLayout
          style={{height: size.size.height-50}}
          editor={editor}
        >
{/* <div>
            <button onClick={onCopy.bind(null, "png")}>
              Copy to Clipboard as PNG
            </button>
            <button onClick={onCopy.bind(null, "svg")}>
              Copy to Clipboard as SVG
            </button>
            <button onClick={onCopy.bind(null, "json")}>
              Copy to Clipboard as JSON
            </button>
          </div> */}


          <Excalidraw
            ref={(api: ExcalidrawImperativeAPI) => setExcalidrawAPI(api)}
            initialData={excalidrawData.data}
            onChange={saveHandle}
            viewModeEnabled={excalidrawData.viewModeEnabled}
            zenModeEnabled={excalidrawData.zenModeEnabled}
            gridModeEnabled={excalidrawData.gridModeEnabled}
            theme={excalidrawData.theme}
            name={editor.name ?? ""}
            // UIOptions={{ canvasActions: { loadScene: false } }}
            renderTopRightUI={renderTopRightUI}
            onLinkOpen={onLinkOpen}
            onLibraryChange={saveLibraryItems}
          >
            {renderMenu()}
          </Excalidraw>

        {/* <div className="export-wrapper button-wrapper">
          <label className="export-wrapper__checkbox">
            <input
              type="checkbox"
              checked={exportWithDarkMode}
              onChange={() => setExportWithDarkMode(!exportWithDarkMode)}
            />
            Export with dark mode
          </label>
          <label className="export-wrapper__checkbox">
            <input
              type="checkbox"
              checked={exportEmbedScene}
              onChange={() => setExportEmbedScene(!exportEmbedScene)}
            />
            Export with embed scene
          </label>
          <button
            onClick={async () => {
              if (!excalidrawAPI) {
                return;
              }
              const svg = await exportToSvg({
                elements: excalidrawAPI?.getSceneElements(),
                appState: {
                  ...initialData.appState,
                  exportWithDarkMode,
                  exportEmbedScene,
                  width: 300,
                  height: 100
                },
                files: excalidrawAPI?.getFiles()
              });
              appRef.current.querySelector(".export-svg").innerHTML =
                svg.outerHTML;
            }}
          >
            Export to SVG
          </button>
          <div className="export export-svg"></div>

          <button
            onClick={async () => {
              if (!excalidrawAPI) {
                return;
              }
              const blob = await exportToBlob({
                elements: excalidrawAPI?.getSceneElements(),
                mimeType: "image/png",
                appState: {
                  ...initialData.appState,
                  exportEmbedScene,
                  exportWithDarkMode
                },
                files: excalidrawAPI?.getFiles()
              });
              setBlobUrl(window.URL.createObjectURL(blob));
            }}
          >
            Export to Blob
          </button>
          <div className="export export-blob">
            <img src={blobUrl} alt="" />
          </div>

          <button
            onClick={async () => {
              if (!excalidrawAPI) {
                return;
              }
              const canvas = await exportToCanvas({
                elements: excalidrawAPI.getSceneElements(),
                appState: {
                  ...initialData.appState,
                  exportWithDarkMode
                },
                files: excalidrawAPI.getFiles()
              });
              const ctx = canvas.getContext("2d")!;
              ctx.font = "30px Virgil";
              ctx.strokeText("My custom text", 50, 60);
              setCanvasUrl(canvas.toDataURL());
            }}
          >
            Export to Canvas
          </button>
          <div className="export export-canvas">
            <img src={canvasUrl} alt="" />
          </div>
        </div> */}
    </DefaultEditLayout>
  );
}

import { useEffect, useState } from "react";

export default function useWindowSize() {
    const [windowSize, setWindowSize] :any= useState({
      width: 0,
      height: 0,
    });
    const [isMobileSize, setIsMobileSize] = useState(false);
 

    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
        setIsMobileSize(window.innerWidth < 1024) //LG
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    
    return {
        size: windowSize,
        isMobileSize: isMobileSize
    }
}

import { FormEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useUserDirectory from "../../../../hooks/userDirectory/useUserDirectory";
import DefaultDialog from "../defaultDialog/DefaultDialog";
import { useNavigate } from "react-router-dom";

type Props = {
    showCreateFolder: boolean
    closeFunction: Function
}

export default function CreateFolderDialog({showCreateFolder,closeFunction}:Props){
    const { t } = useTranslation();
    const folderInputNameRef = useRef<HTMLInputElement|null>(null)
    const [createFolderName,setCreateFolderName] = useState<string>('')
    const [firstOpenedFunction,setFirstOpenedFunction] = useState(false)
    const [loading,setLoading] = useState(false)
    const userDirectory = useUserDirectory()
    const navigate = useNavigate()

    useEffect(() => {
        if(showCreateFolder){
            const name = userDirectory.calculateFolderUniqueNameFromBase()
            setCreateFolderName(name)
            setFirstOpenedFunction(false)
        }else{
            setCreateFolderName('')
        }
    },[showCreateFolder])

    useEffect(() => {
        if(createFolderName && !firstOpenedFunction && showCreateFolder && folderInputNameRef && folderInputNameRef.current){
            folderInputNameRef.current.focus()
            folderInputNameRef.current.setSelectionRange(0, folderInputNameRef.current.value.length)
            setFirstOpenedFunction(true)
        }
    },[createFolderName, firstOpenedFunction, showCreateFolder, folderInputNameRef])


    
    const handleSubmitNewFolder= async (e:FormEvent|null = null) => {
        if(e){
          e.preventDefault()
        }

        if(loading){
          return ;
        }
        setLoading(true)
        const res :any= await userDirectory.createNewFolder(createFolderName)
        closeFunction(true)
        navigate(`/notes/${res.data.folder.token}`)
        setLoading(false)
      }
    return (
        <DefaultDialog
        verticalCenter={true}
        open={showCreateFolder}
        showFooterButtons={true}
        closeIcon={false}
        className="px-5 py-10"
        closeFunction={closeFunction}
        buttonsDisabled={loading}
        mainButtonFunction={() => handleSubmitNewFolder(null)}
      >
        <form className="w-full" onSubmit={handleSubmitNewFolder}>
          <h2 className="text-lg text-black font-bold mb-5">{t('NEW_FOLDER')}</h2>
          <input className="px-3 py-3 outline-1 border border-gray-400 rounded outline-secondary w-full block" 
          ref={folderInputNameRef} value={createFolderName ?? ''} onInput={(e:any) => setCreateFolderName(e.target.value)}/>
        </form>
      </DefaultDialog>
    )

}
import { Dialog, DialogContent, DialogTitle, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import { VscChromeClose } from "react-icons/vsc";

type Props = {
  title?: null | string;
  className?: string;
  withTransition?: boolean;
  fullWidth?: boolean;
  open?: boolean;
  onClose?: any;
  closeFunction?: any;
  children?: React.ReactNode;
  closeIcon?: boolean;
  cancelButton?: boolean;
  cancelButtonLabel?: string;
  mainButton?: boolean;
  buttonsDisabled?: boolean;
  mainButtonFunction?: any;
  mainButtonLabel?: string;
  showFooterButtons?: boolean;
  closeOnBackdrop?:boolean
  verticalCenter?:boolean
  ref?:any
};

export default function DefaultDialog({
  title = null,
  className = "",
  withTransition = true,
  fullWidth = false,
  open = true,
  onClose = () => {},
  closeFunction = () => {},
  children,
  closeIcon = true,
  cancelButton = true,
  cancelButtonLabel = "Cancel",
  mainButton = true,
  buttonsDisabled = false,
  mainButtonFunction = () => {},
  mainButtonLabel = "Create",
  showFooterButtons = false,
  closeOnBackdrop = true,
  verticalCenter = false,
  ...rest
}: Props) {
  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  return (
    <Dialog
      fullWidth={fullWidth}
      open={open}
      onBackdropClick={() => closeOnBackdrop ? closeFunction():() => {}}
      PaperProps={{
        className: `searchContainer w-full rounded absolute ${!verticalCenter ? 'top-36 md:top-48':''} ${className}`,
        style: {maxHeight:'50%'}
      }}
      {...rest}
    >
      {(title || closeIcon) && (
        <div
          className={`flex items-center ${
            title ? "justify-between" : "justify-end"
          }`}
        >
          {title && <h2 className="text-xl">{title}</h2>}
          {closeIcon && (
            <button onClick={(e) => closeFunction()}>
              <VscChromeClose size={18} />
            </button>
          )}
        </div>
      )}
      {children}
      {showFooterButtons && (
        <div className="mt-16 flex items-center justify-between">
          <button
            className="bg-gray-200 px-4 py-2 rounded text-gray-500"
            onClick={(e) => closeFunction()}
            disabled={buttonsDisabled}
          >
            {cancelButtonLabel}
          </button>
          <button
            className="bg-secondary px-4 py-2 rounded text-secondary-text"
            onClick={(e) => mainButtonFunction()}
            disabled={buttonsDisabled}
          >
            {mainButtonLabel}
          </button>
        </div>
      )}
    </Dialog>
  );
}

import CryptUtils from "../crypt/CryptUtils";

export default class LocalStorageUtils {
    static setItem(key:string,value:any, cryptValue = true, type :'localStorage'|'sessionStorage'= 'localStorage'){
        if(!value){
            this.removeItem(key,type)
            return ;
        }

        if(cryptValue){
            value = CryptUtils.encrypt(value)
        }else{
            value = JSON.stringify(value)
        }

        type === 'localStorage' ? localStorage.setItem(key,value):sessionStorage.setItem(key,value)
    }

    static getItem(key:string, cryptValue = true, type :'localStorage'|'sessionStorage'= 'localStorage'){
        let value = type === 'localStorage' ? localStorage.getItem(key): sessionStorage.getItem(key)
        if(value && cryptValue){
            return CryptUtils.decrypt(value)
        }else if(value){
            return JSON.parse(value)
        }
        return value
    }

    static removeItem(keys: string | string[], type :'localStorage'|'sessionStorage'= 'localStorage'){
        if(Array.isArray(keys)){
            keys.forEach(key => type === 'localStorage' ? localStorage.removeItem(key): sessionStorage.removeItem(key))
        }else{
            type === 'localStorage' ? localStorage.removeItem(keys): sessionStorage.removeItem(keys)
        }
    }

    static clear(type :'localStorage'|'sessionStorage'= 'localStorage'){
        type === 'localStorage' ? localStorage.clear():sessionStorage.clear()
    }
}
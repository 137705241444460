import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import JsonUtils from '../../../utils/json/JsonUtils'

export type Directory = {
    lastModified: any[]
    folders: any[]
    notes: any[]
    token: null|string
    name: null|string
    preFolders: any[]
}

export interface SocketState {
    isEstablishingConnection: boolean;
    isConnected: boolean;
    directory: Directory

    isNoteEditEstablishingConnection: boolean;
    noteTokenEditing: string|null;
    editingNote: any
    name: string
    contentBlocks:any
}



const initialState: SocketState = {
    isEstablishingConnection: false,
    isConnected: false,
    directory: {
        lastModified: [],
        folders: [],
        notes: [],
        token: null,
        name: null,
        preFolders: []
    },
    isNoteEditEstablishingConnection: false,
    noteTokenEditing: null,
    editingNote: null,
    name: '',
    contentBlocks: null
};
const REDUCER_KEY = 'SOCKET_NOTES_DIRECTORY'

export const directorySocketReducer = createSlice({
    name: REDUCER_KEY,
    initialState: initialState,
    reducers: {
        startConnecting: (state) => {
            return ;
        },
        setIsEstablishingConnection: (state) => {
            state.isEstablishingConnection = true
        },
        connectionEstablished: (state) => {
            state.isConnected = true
            state.isEstablishingConnection = false
        },
        setDirectory: (state,action:PayloadAction<{directory:any,ignoreOldDirectory?:boolean}>) => {
            if(!action.payload.ignoreOldDirectory){
                const newDirectory = JsonUtils.cloneJson(action.payload.directory)
                newDirectory.folders.forEach((folder:any) => {
                    setFolderOpened(folder,state.directory)
                })

                newDirectory.notes.forEach((note:any) => {
                    setNoteOpened(note,newDirectory)
                })
                state.directory = newDirectory
            }else{
                state.directory = action.payload.directory
            }
        },
        directorySocketDisconnect: (state) => {
            state.isConnected = false
            state.isEstablishingConnection = false
            state.directory = initialState.directory
        },
        createNewFolder: (state,action: PayloadAction<{name:string,parentToken: null|string}>) => {
            return;
        },
        deleteFolder: (state,action: PayloadAction<{folderToken:string}>) => {
            return;
        },
        createNewNote: (state,action: PayloadAction<{name:string,parentToken: null|string,type:number}>) => {
            return;
        },
        deleteNote: (state,action: PayloadAction<{noteToken: null|string}>) => {
            return;
        },
        setNoteFavoriteStatus: (state,action: PayloadAction<{noteToken: string,favoriteStatus:boolean}>) => {
            return;
        },
        setEditNoteContent: (state,action:PayloadAction<{noteToken:string,contentBlocks:any,contentHtml:string|null,contentText:string|null,sendToSocket?:boolean|undefined}>) => {
            const newDirectory = JsonUtils.cloneJson(state.directory)
            setNoteContent(action.payload.noteToken,action.payload.contentBlocks,action.payload.contentHtml,action.payload.contentText,newDirectory)
            state.directory = newDirectory
        },
        setEditNoteName: (state,action:PayloadAction<{noteToken:string,name:string|null,sendToSocket?:boolean|undefined}>) => {
            return ;
        },
        startNoteEditConnection: (state,action:PayloadAction<{noteToken:string}>) => {
            return ;
        },
        setIsNoteEditEstablishingConnection: (state) => {
            state.isNoteEditEstablishingConnection = true
        },
        setNoteTokenEditing: (state,action:PayloadAction<{noteToken:null|string}>) => {
            state.noteTokenEditing = action.payload.noteToken
            state.isNoteEditEstablishingConnection = false
        },
        editNoteSocketDisconnect: (state) => {
            state.isNoteEditEstablishingConnection = false
            state.noteTokenEditing = null
        },
        setEditingNote: (state,action:PayloadAction<{editingNote:any}>) => {
            state.editingNote = action.payload.editingNote
        },
        setEditingNoteName: (state,action:PayloadAction<{name:string|null}>) => {
            state.name = action.payload.name ?? ''
        },
        setEditingNoteContentBlocks: (state,action:PayloadAction<{contentBlocks:string|null}>) => {
            state.contentBlocks = action.payload.contentBlocks
        },  
    }
})

export const directoryActions = directorySocketReducer.actions



export function searchDirectoryFolderByToken(token:string,searchDirectory:any = null,defaultReturn = true){
    let foundFolder :any = null
    searchDirectory.folders.find((folder:any) => {
        if(folder.token == token){
            foundFolder = {...folder,preFolders: []}
        }else if(folder.folders && folder.folders.length > 0){
            const childFolder :any= searchDirectoryFolderByToken(token,folder,false)
            if(childFolder){
                childFolder.preFolders.unshift({
                    name: folder.name,
                    token: folder.token
                })
               
                foundFolder = {...childFolder}
            }
        }
        return foundFolder != null
    })

    return foundFolder
}

export function searchDirectoryNoteByToken(token:string, searchDirectory:any = null){
    let note = null;
    if(searchDirectory.notes){
        note = searchDirectory.notes.find((subNote:any) => subNote.token == token)
    }

    if(!note && searchDirectory.folders){
        searchDirectory.folders.find((folder:any) => {
            note = searchDirectoryNoteByToken(token,folder)
            return note != null
        })
    }

    return note
}

function setNoteOpened(note:any,oldDirectory:any){
    const oldNote = searchDirectoryNoteByToken(note.token,oldDirectory)
    if(oldNote && oldNote.opened){
        note.opened = true
    }
}

function setNoteContent(noteToken:string,contentBlocks:any,contentHtml:string|null,contentText:string|null,oldDirectory:any){
    const oldNote = searchDirectoryNoteByToken(noteToken,oldDirectory)
    if(oldNote){
        oldNote.contentBlocks = contentBlocks
        oldNote.contentHtml = contentHtml
        oldNote.contentText = contentText
    }
}

export function setNoteName(noteToken:string,name:string|null,oldDirectory:any){
    const oldNote = searchDirectoryNoteByToken(noteToken,oldDirectory)
    if(oldNote){
        oldNote.name = name
    }
}

function setFolderOpened(folder:any,oldDirectory:any){
    const oldFolder = searchDirectoryFolderByToken(folder.token,oldDirectory)
    if(oldFolder && oldFolder.opened){
        folder.opened = true
    }
    folder.notes.forEach((note:any) => {
        setNoteOpened(note,oldDirectory)
    })

    folder.folders.forEach((fol:any) => {
        setFolderOpened(fol,oldDirectory)
    })

}
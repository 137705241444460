import { createSlice } from '@reduxjs/toolkit'
import localStorageUtils from '../../../utils/storage/LocalStorageUtils'

const REDUCER_KEY = 'AUTH_USER'
export const userReducer = createSlice({
    name: REDUCER_KEY,
    initialState: {
        value: getUserInitialState()
    },
    reducers: {
        login: (state,action:any) => {
            state.value = action.payload
            setUserState(state.value)
        },
        logout: (state) => {
            state.value = null
            setUserState(state.value)
        }
    }
})

function getUserInitialState(){
    return localStorageUtils.getItem(REDUCER_KEY,true,'sessionStorage')
}

function setUserState(value:any){
    localStorageUtils.setItem(REDUCER_KEY,value,true,'sessionStorage')
}
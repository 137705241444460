/* eslint-disable react-hooks/exhaustive-deps */
import React, {
    FormEvent,
    FormEventHandler,
    useEffect,
    useRef,
    useState,
  } from "react";
import EditorJS from "@editorjs/editorjs";
import { useTranslation } from "react-i18next";
import { getEditorJsTools } from "../../../../../ constants/editorJs/editorJsConstants";
import styles from './NoteEditor.module.css'
import { TextareaAutosize } from "@mui/material";
import JsonUtils from "../../../../../utils/json/JsonUtils";
const DragDrop = require("editorjs-drag-drop");
const Undo = require("editorjs-undo");

export default function NoteEditor({editor,loading,setLoading}:any){
    let editorJs :null|EditorJS= null;
    const { t } = useTranslation();
    const editorRef = useRef<HTMLDivElement>(null);
    let loadingEditorJs = false
    let timeOutSave :any= null
    const [editorJsHook,setEditorJsHook] = useState<any>(null)
    const nameRef = useRef<HTMLTextAreaElement>(null);

    const i18n = {
        messages: {
          toolNames: {
            Text: t("TEXT"),
            Heading: t("HEADING"),
            List: t("LIST"),
            Warning: t("WARNING"),
            Checklist: t("CHECKLIST"),
            Quote: t("QUOTE"),
            Code: t("CODE"),
            Delimiter: t("DELIMITER"),
            "Raw HTML": t("RAW_HTML"),
            Table: t("TABLE"),
            Link: t("LINK"),
            Marker: t("MARKER"),
            Bold: t("BOLD"),
            Italic: t("ITALIC"),
            InlineCode: t("INLINE_CODE"),
            COLOR: t("COLOR"),
          },
          blockTunes: {
            delete: {
              Delete: t("DELETE"),
            },
            moveUp: {
              "Move up": t("MOVE_UP"),
            },
            moveDown: {
              "Move down": t("MOVE_DOWN"),
            },
          },
          ui: {
            blockTunes: {
              toggler: {
                "Click to tune": t("CLICK_TO_TUNE"),
                "or drag to move": t("OR_DRAG_TO_MOVE"),
              },
            },
            inlineToolbar: {
              converter: {
                "Convert to": t("CONVERT_TO"),
              },
            },
            toolbar: {
              toolbox: {
                Add: t("ADD"),
                Tab: t("TAB"),
              },
            },
          },
        },
      };

      useEffect(() => {
        const editorElement = document.getElementById('editor')
        if(!editorElement){
          return
        }

        if (editor.note && editorRef.current && !loadingEditorJs) {
    
          startEditorJs();
        }
    
        return () => {
          if (editorJs && editorJs.destroy) {
            editorJs.destroy()
          }else if(editorJsHook && editorJsHook.destroy){
            editorJsHook.destroy()
          }else{
            const element = document.getElementById("editor")
            if(element){
                element.innerHTML = ""
            }
          }
          setTimeout(() => {
            editorJs = null;
            loadingEditorJs = false
            setEditorJsHook(null)
          },100)
        }
      }, []);

      async function startEditorJs() {
        if (!editor.isNoteEditEstablishingConnection) {
          setLoading(true)
          loadingEditorJs = true
          const newEditorJs = await new EditorJS({
            holder: "editor",
            placeholder: t("CONTENT"),
            tools: editor.note.permission.canEdit === true ? getEditorJsTools(`${editor.note.token}`) : {},
            i18n,
            data: JsonUtils.cloneJson(editor.note.contentBlocks),
            readOnly: !editor.note.permission.canEdit,
            onReady: () => {
              if (editorJs) {
                new DragDrop(editorJs);
                // new Undo({ editor });
              }
              setLoading(false)
              loadingEditorJs = false
            },
            onChange: async (data) => {
              if (newEditorJs) {
                console.log("save!!",data)
                const outputData = await newEditorJs.save();
                let contentHtml :any= null;
                let contentText :any= null
                if (
                  editorRef.current &&
                  editorRef.current.children.length > 0 &&
                  editorRef.current.children[0].children.length > 0
                ) {
                  contentHtml = `${editorRef.current.children[0].children[0].innerHTML}`;
                  contentText = `${editorRef.current.children[0].children[0].textContent}`
                }
    
                if(timeOutSave){
                  clearTimeout(timeOutSave)
                }
                timeOutSave = setTimeout(() => {
                  editor.changeNoteContent(JsonUtils.cloneJson(outputData), contentHtml,contentText, null);
                },150)
              }
            },
          });
          editorJs = newEditorJs
          setEditorJsHook(editorJs)
        }
      }


  function handleInputName(e: FormEvent<HTMLTextAreaElement>) {
    if(editor.note.permission.canEdit){
      const text = e.currentTarget.value.replaceAll(/\n/g, "");
      editor.changeNoteName(text);
    }
  }

  function handleKeyUpName(e: React.KeyboardEvent<HTMLTextAreaElement>) {
    e.stopPropagation()
    if(editor.note.permission.canEdit){
      if (e.key === "Enter" && editorJsHook) {
        editorJsHook.focus()
      }
    }
  }


    return (
        <>
        <div className="w-full mt-5 md:mt-10 m-auto px-2 md:px-10">
            <TextareaAutosize
              className={`w-full block outline-none cursor-text text-gray-700 ${styles.name} resize-none`}
              style={{ fontSize: 35, fontWeight: "bold" }}
              onInput={handleInputName}
              value={editor.name ?? ""}
              ref={nameRef}
              placeholder={t("NOTE_NAME")}
              onKeyUpCapture={handleKeyUpName}
              readOnly={!editor.note.permission.canEdit}
            />
          </div>
            <div className="w-full mt-2">
            <div
              className={`inline-block w-full h-full outline-none cursor-text px-2 md:px-10 ${styles.hyphens}`}
              // contentEditable
              // role="textbox"
              // aria-label="Note"
              // tabIndex={0}
              // spellCheck={false}
              id="editor"
              // onInput={changeContent}
              ref={editorRef}
            />
          </div>
        </>
    )
}
import { useEffect, useRef, useState } from "react";
import { useBeforeunload } from "react-beforeunload";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setNoteName } from "../../reducers/notes/socket/directorySocketReducer";
import { RootState } from "../../reducers/reducers";
import JsonUtils from "../../utils/json/JsonUtils";
import { ResponseType } from "../api/useApi";
import useAuth from "../auth/useAuth";
import useUserDirectory from "../userDirectory/useUserDirectory";

export default function useEditor():useEditorType {
  const userDirectory = useUserDirectory()
  const auth = useAuth()
  const {token} = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
      noteChangeToken()
  },[token,userDirectory.directory])


  useEffect(() => {
    if(!auth.user && (userDirectory.directoryState.noteTokenEditing || userDirectory.directoryState.isNoteEditEstablishingConnection)){
        disconnect()
    }else if(auth.user && userDirectory.directoryState.noteTokenEditing !== token && !userDirectory.directoryState.isNoteEditEstablishingConnection){
      // dispatch(userDirectory.DIRECTORY_ACTIONS.setEditingNoteName({name:''}))
      // dispatch(userDirectory.DIRECTORY_ACTIONS.setEditingNoteContentBlocks({contentBlocks:null}))

      disconnect()
      startConnection()
    }
  },[auth.user,token])

  useBeforeunload(() =>  
    disconnect()
  );

  async function startConnection(){
    if(token){
      const res :any = await dispatch(userDirectory.DIRECTORY_ACTIONS.startNoteEditConnection({noteToken: token}))
      console.log("res",res)
      if(res && res.success){
        userDirectory.setEditingNote(res.data.note)
      }else{
        userDirectory.setEditingNote(null)
        navigate('/')
      }
    }else{
      await disconnect()
    }
  }

  function noteChangeToken(){
    const newNote = userDirectory.searchNoteByToken(token)
    userDirectory.setEditingNote(newNote)
  }

  async function changeNoteContent(contentBlocks:any,contentHtml:string|null = null, contentText:string|null = null,noteToken:string|null = null,sendToSocket = true){
    await dispatch(userDirectory.DIRECTORY_ACTIONS.setEditingNoteContentBlocks({contentBlocks}))


    if(token && !noteToken && sendToSocket){
      noteToken = token
    }

    if(noteToken){
      await dispatch(userDirectory.DIRECTORY_ACTIONS.setEditNoteContent({contentBlocks,contentHtml,contentText,noteToken,sendToSocket}))
    }
  }

  async function changeNoteName(name:string|null,noteToken:string|null,sendToSocket = true){
    await dispatch(userDirectory.DIRECTORY_ACTIONS.setEditingNoteName({name}))
    if(token && !noteToken && sendToSocket){
      noteToken = token
    }

    if(noteToken){
      dispatch(userDirectory.DIRECTORY_ACTIONS.setEditNoteName({name,noteToken,sendToSocket}))
    }
  }

  async function disconnect(){
    await dispatch(userDirectory.DIRECTORY_ACTIONS.editNoteSocketDisconnect())
}

 return  {
    note:userDirectory.editingNote,
    changeNoteContent,
    userDirectory,
    noteChangeToken,
    changeNoteName,
    name:userDirectory.editingNoteName,
    contentBlocks: userDirectory.editingNoteContentBlocks,
    isNoteEditEstablishingConnection: userDirectory.directoryState.isNoteEditEstablishingConnection
  };

}

export type useEditorType = {
  changeNoteContent: Function
  noteChangeToken: Function
  changeNoteName:Function
  userDirectory:any
  note:any
  name: string|null
  contentBlocks:any,
  isNoteEditEstablishingConnection: boolean
};

export type DirectoryType = {
  folders: FolderType[];
  notes: NoteType[];
};

export type FolderType = {
  name: string;
  namespace: string;
  opened?: boolean;
  notes: NoteType[];
};

export type NoteType = {
  name: string;
  namespace: string;
  favorite?: boolean;
  opened?: boolean;
};

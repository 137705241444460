import { style } from "@mui/system";
import { MdFolder, MdFolderOpen } from "react-icons/md";
import styles from "../EditorSidebar.module.css";
import NoteButton from "./NoteButton.component";
export default function FolderButtonComponent({
  folder,
  openFolder,
  sidebar,
  childDeep = 0,
  parentOpened = false,
  ...rest
}: any) {


  return (
    <div {...rest} className={`${folder.opened ? styles.directoryContainerFullOpened:styles.directoryContainerFull}`}         
    
    >
      <div className={styles.verticalLine}></div>
      
      <button
        className={`block w-full cursor-pointer line-clamp-1 folder ${
          sidebar.sidebar
            ? styles.directoryButtonFull
            : styles.directoryContainerIcon
        }
              ${folder.lastOpened ? styles.lastOpened : (folder.opened ? styles.opened:'')}
              `}
        onClick={(e: any) => openFolder(folder)}
        data-folder-token={folder.token}
      >
        <div className="flex justify-end gap-1" style={{width:26,minWidth:26}}>
          {/* {folder.opened ?  <IoIosArrowDown />:<IoIosArrowForward />} */}
          {folder.opened ? <MdFolderOpen size={18} /> : <MdFolder size={18} />}
        </div>
        <div
          className={`line-clamp-1 ${
            folder.opened ? "font-bold" : "font-normal"
          }`}
          style={{ fontSize: 15 }}
        >
          {folder.name}
        </div>
      </button>
      {folder.opened &&
        folder.folders &&
        folder.folders.map((subFolder: any, i: number) => (
          <FolderButtonComponent
            folder={subFolder}
            openFolder={openFolder}
            sidebar={sidebar}
            key={i}
            childDeep={childDeep+1}
            parentOpened={folder.opened}
          />
        ))}
      {folder.notes && folder.opened && folder.notes.map((note:any,i:number) => (
        <NoteButton             onClick={sidebar.closeMobileSidebar}
        sidebar={sidebar} note={note} key={i} childDeep={childDeep+1}/>
      ))}
    </div>
  );
}

import logo from "../../../../assets/images/logos/postmetoo-nobackground.png";
import chromeLogo from "../../../../assets/images/logos/chrome.png";
import { IoLogOutOutline, IoPersonOutline, IoSearchOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import styles from "./Navbar.module.css";
import useAuth from "../../../../hooks/auth/useAuth";
import React from "react";
import { useTranslation } from "react-i18next";
import SearchContainer from "../../../containers/searchContainer/SearchContainer";

export default function NavbarComponent() {
  const { t } = useTranslation();
  const auth = useAuth();
  const [showSearchIcon, setShowSearchIcon] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function logoutClicked(){
    handleClose()
    auth.logout()
  }


  return (
    <nav
      className="px-2 md:px-5 gap-3 md:gap-5 flex items-center border-b border-gray-100 justify-between"
      style={{ height: 50 }}
    >
      <Link className="flex gap-2 items-center" to={"/"}>
        <img src={logo} alt="postmetoo logo" style={{ height: 28 }} />
        <h4 className={`font-bold ${auth.user ? 'hidden md:block':''}`}>POSTMETOO</h4>
      </Link>
      {auth.user && (
        <div
          className="flex-1 flex rounded-lg border px-3 w-full gap-2 items-center"
          style={{ maxWidth: 500, paddingTop: 8, paddingBottom: 8 }}
          onClick={() => setShowSearchIcon(true)}
        >
          <IoSearchOutline
            className={`text-gray-500 ${
              styles.showingIcon} ${styles.searchIcon}`}
          />
          <div
            placeholder={t('SEARCH_YOUR_NOTES')}
            className="text-sm outline-none w-full h-full text-gray-500 cursor-pointer"
            // onFocus={(e: any) => setShowSearchIcon(false)}
            // onBlur={(e: any) => setShowSearchIcon(true)}
          >{t('SEARCH_YOUR_NOTES')}</div>
        </div>
      )}
      <div className="flex gap-4 items-center">
        <a
          className="border rounded-lg text-xs px-2 gap-2 items-center text-gray-500 hidden md:flex"
          href="/"
          style={{ height: 35 }}
        >
          <img src={chromeLogo} alt="Chrome extension" style={{ height: 23 }} />
          {/* {t('ADD_TO_CHROME')} */}
          {t('CHROME_EXTENSION_COMING_SOON')}
        </a>
        {auth.user ? (
          <div>
            <button
              id="user-menu"
              className="rounded-full flex items-center justify-center border"
              style={{ height: 40, width: 40 }}
              onClick={handleClick}
            >
              <IoPersonOutline className="text-gray-500" />
            </button>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "user-button",
              }}
            >
              {/* <MenuItem onClick={handleClose}>
                Profile
              </MenuItem>
              <MenuItem onClick={handleClose}>
                My account
              </MenuItem> */}
              <MenuItem onClick={logoutClicked} className='flex gap-x-2 items-center'>
                <IoLogOutOutline className="text-red-600" size={18}/>
                {t('LOGOUT')}
              </MenuItem>
            </Menu>
          </div>
        ) : (
          <div className="flex gap-3 items-center">
            <Link
              className="text-xs rounded text-gray-600 flex items-center justify-center bg-secondary font-bold"
              style={{ height: 33, width: 90 }}
              to="/login"
            >
              {t('LOGIN')}
            </Link>
            <Link
              className="text-xs rounded bg-gray-200 text-gray-600 hidden md:flex items-center justify-center font-bold"
              style={{ height: 33, width: 90 }}
              to="/register"
            >
              {t('REGISTER')}
            </Link>
          </div>
        )}
      </div>
      <SearchContainer open={showSearchIcon} closeSearchFunction={() => setShowSearchIcon(false)}/>
    </nav>
  );
}

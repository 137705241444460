/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
} from "react";
import DefaultEditLayout from "../../../components/layouts/defaultEditLayout/DefaultEditLayout.layout";
import useEditor from "../../../hooks/editor/useEditor";
import useUserDirectory from "../../../hooks/userDirectory/useUserDirectory";
import LoaderContainer from "../../../components/containers/loaderContainer/LoaderContainer";
import NoteEditor from "./components/noteEditor/NoteEditor";
import DrawEditor from "./components/drawEditor/DrawEditor";

export default function NotePage() {
  const editor = useEditor();
  const directory = useUserDirectory();
  const [loading,setLoading] = useState(false)

  return (
    <React.Fragment>
      {directory.isConnected && editor.note ?(
        <DefaultEditLayout
          className="px-5 md:px-5 lg:px-40 xl:px-60 pb-10"
          editor={editor}
        >
          {editor.note.type === 1 ? 
          <DrawEditor setLoading={setLoading} loading={loading} editor={editor} />
          :<NoteEditor setLoading={setLoading} loading={loading} editor={editor} />}
        </DefaultEditLayout>
      ):null}
      <LoaderContainer visible={loading}/>
    </React.Fragment>
  );
}

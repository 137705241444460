import { toast } from "react-toastify";

export type Toast = "info" | "success" | "warning" | "error" | "default";

export default class ToastUtils {
  static showToast(
    type: Toast = "default",
    {
      message = "Alerta",
      position = "top-right",
      autoClose = 5000,
      hideProgressBar = false,
      closeOnClick = true,
      pauseOnHover = false,
      pauseOnFocusLoss = false,
    }
  ) {
    const options: any = {
      position,
      autoClose,
      hideProgressBar,
      closeOnClick,
      pauseOnFocusLoss,
      pauseOnHover
    };
  
    switch (type) {
      case "info":
        toast.info(message, options);
        break;
      case "success":
        toast.success(message, options);
        break;
      case "warning":
        toast.warn(message, options);
        break;
      case "error":
        toast.error(message, options);
        break;
      default:
        toast(message, options);
        break;
    }
  }
  
}